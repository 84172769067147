export const handleFormInputChange = (
  e,
  setFormData,
  setErrors
) => {
  const { name, value } = e.target;
  setFormData((prevState) => ({
    ...prevState,
    [name]: value,
  }));

  setErrors((prevState) => ({
    ...prevState,
    [name]: "",
  }));
};
