import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  // signInWithFacebook,
  signInWithGoogle,
  signIn,
} from "../store/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setUpUserAndNavigate } from "../misc/setUpUser";
import { handleFormInputChange } from "../misc/formUtils";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isEmployerPage, setIsEmployerPage] = useState(false);
  const [isPartnerPage, setIsPartnerPage] = useState(false);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const path = location.pathname;
    setIsEmployerPage(path?.startsWith("/employer/"));
    setIsPartnerPage(path?.startsWith("/partner/"));
  }, [location.pathname]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    authError: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    authError: "",
  });

  const handleLoginGoogle = async () => {
    dispatch(signInWithGoogle()).then((authUser) => {
      handleLoginResponse(dispatch, authUser, navigate);
    });
  };

  const handleLoginResponse = (dispatch, authUser, navigate) => {
    if (!authUser.error) {
      setUpUserAndNavigate(dispatch, authUser, navigate);
    } else {
      const newErrors = { ...errors };
      newErrors.email = "";
      newErrors.password = "";
      newErrors.authError = "Invalid email or password. Please try again";
      setErrors(newErrors);
    }
  };

  // const handleLoginFacebook = async () => {
  //   dispatch(signInWithFacebook()).then((authUser) => {
  //     handleLoginResponse(dispatch, authUser, navigate);
  //   });
  // };

  const handleInputChange = (e) => {
    handleFormInputChange(e, setFormData, setErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitForm();
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.email || formData.email.trim() === "") {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
    } else {
      newErrors.email = "";
    }

    if (!formData.password || formData.password?.trim() === "") {
      newErrors.password = "Please enter a valid password.";
      valid = false;
    } else {
      newErrors.password = "";
    }

    newErrors.authError = "";

    setErrors(newErrors);
    return valid;
  };

  const submitForm = async () => {
    let userCredentials = {
      email: formData.email,
      password: formData.password,
    };

    dispatch(signIn(userCredentials)).then((authUser) => {
      handleLoginResponse(dispatch, authUser, navigate);
    });

    setFormData({
      email: "",
      password: "",
    });
  };

  useEffect(() => {
    if (auth.error && auth.error?.code === "auth/email-already-in-use") {
      // Display the error in an alert box or any other UI element
      alert("Email already in use, please sign in");
    }
  }, [auth]);

  return (
    <>
      <section className=' login-register'>
        <div className='container'>
          <div className='row login-register-cover'>
            <div className='col-lg-4 col-md-6 col-sm-12 mx-auto'>
              <div className='text-center'>
                <p className='font-sm text-brand-2'>Welcome back! </p>
                <h2 className='mt-10 mb-5 text-brand-1'>
                  {isEmployerPage
                    ? "Employer "
                    : isPartnerPage
                    ? "Partner "
                    : "Member "}
                  Login
                </h2>
                <p className='font-sm text-muted mb-30'>
                  {(isEmployerPage || isPartnerPage) &&
                    " Access to all features. No credit card required."}
                </p>
                {/* {!isEmployerPage && ( */}
                <button
                  className='btn social-login hover-up mb-20 '
                  onClick={handleLoginGoogle}
                >
                  <img
                    src='/assets/imgs/template/icons/icon-google.svg'
                    alt='Sign In With Google'
                  />
                  <strong>Sign in with Google</strong>
                </button>
                {/* )} */}
                {/* <button
                  className="btn social-login hover-up mb-20"
                  onClick={handleLoginFacebook}
                >
                  <img
                    src="assets/imgs/template/icons/icon-google.svg"
                    alt="jobbox"
                  />
                  <strong>Sign in with Facebook</strong>
                </button> */}
                {/* {!isEmployerPage && ( */}
                <div className='divider-text-center'>
                  <span>Or continue with</span>
                </div>
                {/* )} */}
              </div>
              <form
                className='login-register text-start mt-20'
                onSubmit={handleSubmit}
              >
                {" "}
                <div className='form-group'>
                  <label className='form-label' htmlFor='input-1'>
                    Email address
                  </label>
                  <input
                    className='form-control'
                    id='email'
                    name='email'
                    type='email'
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder='Email'
                  />
                </div>
                {errors.email && (
                  <div className='text-danger'>{errors.email}</div>
                )}
                <div className='form-group'>
                  <label className='form-label' htmlFor='input-4'>
                    Password *
                  </label>
                  <input
                    className='form-control'
                    id='password'
                    name='password'
                    type='password'
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder='************'
                  />
                </div>
                {errors.password && (
                  <div className='text-danger'>{errors.password}</div>
                )}
                {errors.authError && (
                  <div className='text-danger'>{errors.authError}</div>
                )}
                <div className='login_footer form-group d-flex justify-content-between pt-20'>
                  <label className='cb-container'></label>
                  <Link className='text-muted' to='/password'>
                    Forgot Password
                  </Link>
                </div>
                <div className='form-group pt-10'>
                  <button
                    className='btn btn-brand-1 hover-up w-100 bg-[#05264E]'
                    type='submit'
                  >
                    Login
                  </button>
                </div>
                {/* {isEmployerPage && (
                  <div className="text-muted text-center pt-10">
                    Don't have an Account?
                    {isEmployerPage ? (
                      <Link to="/employer/register">Sign up</Link>
                    ) : isPartnerPage ? (
                      <Link to="/partner/register">Sign up</Link>
                    ) : (
                      <Link to="/register">Sign up</Link>
                    )}
                  </div>
                )} */}
              </form>

              <div className='text-muted text-center pt-10'>
                Don't have an Account? &nbsp;
                {isEmployerPage ? (
                  <Link
                    className='text-brand-2 font-weight-bold'
                    to='/employer/register'
                  >
                    Sign up
                  </Link>
                ) : isPartnerPage ? (
                  <Link
                    className='text-brand-2 font-weight-bold'
                    to='/partner/register'
                  >
                    Sign up
                  </Link>
                ) : (
                  <Link
                    className='text-brand-2 font-weight-bold'
                    to='/register'
                  >
                    Sign up
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Auth;
