import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";


SwiperCore.use([Navigation]);

const TestimonialSlider1 = () => (
    <>
        <div className="swiper-container swiper-group-3">
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                // loop={true}
                // className="swiper-wrapper pb-70 pt-5"
                breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    400:{
                      slidesPerView:2,
                    },
                    639: {
                      slidesPerView: 3,
                    },
                    865:{
                      slidesPerView:4
                    },
                    1000:{
                      slidesPerView:5
                    },
                    1500:{
                      slidesPerView:6
                    },
                    1700:{
                      slidesPerView:7
                    }
                  }}
                
            >
                <SwiperSlide>
                    <div className="card-grid-6 hover-up">
                        <div className="card-text-desc mt-10">
                            <p className="font-md color-text-paragraph">I was struggling with my coding assignment and had a tight deadline. I came across this website and decided to give it a shot. The service was exceptional! They not only helped me solve the coding problem but also provided a detailed explanation that improved my understanding. I'll definitely be using their services again.</p>
                        </div>
                        <div className="card-image">
                            <div className="image">
                                <figure>
                                    <img alt="jobBox" src="assets/imgs/page/about/user1.png" />
                                </figure>
                            </div>
                            <div className="card-profile">
                                <h6>David Williams</h6>
                                {/* <span>Businessmen</span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card-grid-6 hover-up">
                        <div className="card-text-desc mt-10">
                            <p className="font-md color-text-paragraph">As a professional in the field of data analysis, I often need to write reports and presentations. This website has been a lifesaver for me. Their writing services are top-notch, and the team is incredibly responsive and attentive to my requirements. Thanks to them, I've been able to deliver high-quality work to my clients on time."</p>
                        </div>
                        <div className="card-image">
                            <div className="image">
                                <figure>
                                    <img alt="jobBox" src="assets/imgs/page/about/user2.png" />
                                </figure>
                            </div>
                            <div className="card-profile">
                                <h6>Mark Adair</h6>
                                {/* <span></span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card-grid-6 hover-up">
                        <div className="card-text-desc mt-10">
                            <p className="font-md color-text-paragraph">I'm a self-taught programmer, and sometimes I hit roadblocks in my learning journey. The coding tutorials and resources provided by this website have been instrumental in helping me overcome challenges. The step-by-step explanations and hands-on examples make complex concepts much easier to grasp.</p>
                        </div>
                        <div className="card-image">
                            <div className="image">
                                <figure>
                                    <img alt="jobBox" src="assets/imgs/page/about/user3.png" />
                                </figure>
                            </div>
                            <div className="card-profile">
                                <h6>Emily Lee</h6>
                                {/* <span></span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card-grid-6 hover-up">
                        <div className="card-text-desc mt-10">
                            <p className="font-md color-text-paragraph">I was skeptical about using an online service for my writing needs, but this website exceeded my expectations. The quality of their work and the professionalism of their team are outstanding. They tailored my report exactly as I needed and delivered it promptly. I highly recommend their services to anyone seeking reliable assistance</p>
                        </div>
                        <div className="card-image">
                            <div className="image">
                                <figure>
                                    <img alt="jobBox" src="assets/imgs/page/about/user1.png" />
                                </figure>
                            </div>
                            <div className="card-profile">
                                <h6>Luke David</h6>
                                {/* <span>Businessmen</span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
        <div className="swiper-pagination swiper-pagination3" />
    </>
);

export default TestimonialSlider1;
