import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase";
// import { Functions } from "firebase/functions";
import {
  collection,
  addDoc
} from "firebase/firestore";

// const sendEmail = async (formData) => {
//   const url = 'https://us-central1-just-easy-jobs-50739.cloudfunctions.net/sendEmail';
//   // const data = {
//   //   name: formData.name,
//   //   email: formData.email,
//   //   phone: formData.phone,
//   //   institution: formData.institution,
//   //   message: formData.institution,
//   // };

//   const data = formData
//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     });

//     if (response.ok) {
//       const result = await response.text();
//       console.log('Email sent successfully:', result);
//     } else {
//       console.error('Failed to send email:', response.statusText);
//     }
//   } catch (error) {
//     console.error('Error sending email:', error);
//   }
// };



export const saveContactForm = createAsyncThunk(
    "ContactForm/saveContactForm",
    async (formData, thunkAPI) => {
      try {
        const docRef = await addDoc(collection(db, "contact-form-data"), formData);

  // await sendEmail(formData)
          return docRef;
      } catch (error) {
        console.log("errorrrr" , error)
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  

  const contactFormSlice = createSlice({
    name: "contactForm",
    initialState: {
      formData: {
        name: "",
        email: "",
        phone: "",
        institution: "",
        message: ""
      },
      isLoading: false,
      error: null,
      contactFormResponse: null,
    },
    reducers: {
      setFormData: (state, action) => {
        state.formData = action.payload;
      },
      resetForm: (state) => {
        state.formData = {
          name: "",
          email: "",
          phone: "",
          institution: "",
          message: ""
        };
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(saveContactForm.pending, (state) => {
          state.isLoading = true;
          state.error = null;
          state.contactFormResponse = null;
        })
        .addCase(saveContactForm.fulfilled, (state, action) => {
          state.isLoading = false;
          state.contactFormResponse = action.payload;
          state.error = null;
        })
        .addCase(saveContactForm.rejected, (state, action) => {
          state.isLoading = false;
          state.contactFormResponse = null;
          state.error = action.error.message;
        });
    },
  });
  
  export const { setFormData, resetForm } = contactFormSlice.actions;
  export default contactFormSlice.reducer;
  
  
  
  
  