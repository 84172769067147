import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { Provider } from "react-redux";
import store from "./store/store";
import Jobs from "./pages/Jobs";
import Auth from "./pages/Auth";
import PasswordReset from "./pages/PasswordReset";
import Profile from "./pages/Profile";
import CandidateGrid from "./pages/CandidateGrid";
import Register from "./pages/Register";
import { authenticate } from "./services/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import Layout from "./components/Layout/Layout";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-hooks-web";
import EmployerDashboard from "./pages/EmployerDashboard";
import PartnerJobs from "./pages/PartnerJobs";
import PostJob from "./pages/PostJob";
import PrivacyPolicy from "./pages/legal/privacy-policy";
import CookiePolicy from "./pages/legal/cookie-policy";
// import StudentInfo from "./components/dashboard_data/studentInfo";
import TOS from "./pages/legal/terms-and-conditions";
import ContactForm from "./pages/ContactUs";
import Dashboard from "./pages/PartnerDashboardV2";
import About from "./pages/AboutUs";

const searchClient = algoliasearch(
  "3D28CMOSFM",
  "d939bb677d6c0cb3323490ea6dffd87d"
);

function App() {
  useEffect(() => {
    onAuthStateChanged(authenticate, (user) => {
      if (user) {
        localStorage.setItem("isLoggedIn", true);
      } else {
        localStorage.setItem("isLoggedIn", false);
      }
    });
  }, []);

  return (
    <InstantSearch searchClient={searchClient} indexName='job_search_index'>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              {/* <Route index element={<StudentsListOverview />} /> */}
              <Route index element={<Home />} />
              {/* <Route path="jobs" element={<StudentsListOverview />} /> */}
              <Route path='about-us' element={<About />} />
              {/* <Route path='auth' element={<Auth />} />
              <Route path='password' element={<PasswordReset />} />
              <Route path='profile' element={<Profile />} />
              <Route path='job-candidates' element={<CandidateGrid />} /> */}
              {/* <Route path='register' element={<Register />} /> */}
            </Route>
            <Route path='about-us' element={<Layout />}>
              <Route index element={<About />} />
              </Route>

            <Route path='blogs' element={<Layout />}>
              <Route index element={<Auth />} />
              {/* <Route path='auth' element={<Auth />} />
              <Route path='dashboard' element={<EmployerDashboard />} />
              <Route path='register' element={<Register />} />
              <Route path='post-job' element={<PostJob />} /> */}
            </Route>
            <Route path='contact-us' element={<Layout />}>
              <Route index element={<ContactForm />} />
              {/* <Route path='register' element={<ContactForm />} />
              <Route path='auth' element={<Auth />} /> */}
              {/* <Route path="dashboard" element={<CandidateGrid />} /> */}

              {/* <Route path='dashboard' element={<Dashboard />}></Route> */}
              {/* <Route path="register" element={<Register />} /> */}
              <Route path='about-us' element={<About />} />
              {/* <Route path='dashboard/jobs/:id' element={<StudentInfo />} /> */}
            </Route>
            <Route path='legal' element={<Layout />}>
              <Route path='privacy-policy' element={<PrivacyPolicy />} />
              <Route path='cookie-policy' element={<CookiePolicy />} />
              <Route path='terms-of-service' element={<TOS />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </InstantSearch>
  );
}

export default App;
