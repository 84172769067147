import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";

const setPartnerStudentDoc = (partnerStudentDoc) => {
  let partnerStudent = undefined;
  console.log(partnerStudentDoc, "partnerStudentDocpartnerStudentDocpartnerStudentDoc");
  if (partnerStudentDoc.exists()) {
    partnerStudent = {
      id: partnerStudentDoc.data().id,
      email: partnerStudentDoc.data().email,
      name: partnerStudentDoc.data().name,
      photoUrl: partnerStudentDoc.data().photoUrl,
      partnerId: partnerStudentDoc.data().partnerId,
      skill: partnerStudentDoc.data().skill,
      bio: partnerStudentDoc.data().bio,
      contactNumber: partnerStudentDoc.data().contactNumber,
      state: partnerStudentDoc.data().state,
      city: partnerStudentDoc.data().city,
      zipCode: partnerStudentDoc.data().zipCode,
      addressLine1: partnerStudentDoc.data().addressLine1,
      addressLine2: partnerStudentDoc.data().addressLine2,
      isPartnerAdmin: partnerStudentDoc.data().isPartnerAdmin,
      program: partnerStudentDoc.data().program,
      isEmployer: partnerStudentDoc.data().isEmployer,
      isPartner: partnerStudentDoc.data().isPartner,
      background :  partnerStudentDoc.data().background,
      jobsSought: partnerStudentDoc.data().jobsSought
    };
  }

  return partnerStudent;
};

export const fetchPartnerStudentsByStatus = createAsyncThunk(
  "partnerStudents/fetchPartnerStudents",
  async (ids = undefined) => {
    console.log('ids slected ', ids)
    let tempCollection;
    if (ids && ids.length > 0) {
      tempCollection = query(collection(db, "users"), where("id", "in", ids));
    }
    
    else {
      return []
    }

    const querySnapshot = await getDocs(tempCollection);

    let partnerStudents = querySnapshot.docs.map((doc) => setPartnerStudentDoc(doc));
    return partnerStudents;
  }
);
export const fetchPartnerStudents = createAsyncThunk(
  "partnerStudents/fetchPartnerStudents",
  async (ids = undefined) => {
    console.log('ids slected ', ids)
    let tempCollection;
    if (ids && ids.length > 0) {
      tempCollection = query(collection(db, "users"), where("id", "in", ids));
    } else {
      tempCollection = collection(db, "users");
    }

    const querySnapshot = await getDocs(tempCollection);

    let partnerStudents = querySnapshot.docs.map((doc) => setPartnerStudentDoc(doc));
    return partnerStudents;
  }
);

export const fetchPartnerStudent = createAsyncThunk(
  "partnerStudents/fetchPartnerStudent",
  async (partnerStudentId) => {
    const partnerStudentDoc = await getDoc(doc(db, "users", partnerStudentId));
    return setPartnerStudentDoc(partnerStudentDoc);
  }
);

export const fetchPartnerStudentByEmail = createAsyncThunk(
  "partnerStudents/fetchPartnerStudentByEmail",
  async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));

    const querySnapshot = await getDocs(q);
    let partnerStudents = querySnapshot.docs.map((doc) => setPartnerStudentDoc(doc));
    return partnerStudents[0];
  }
);

export const setPartnerStudent = createAsyncThunk(
  "partnerStudents/setPartnerStudent",
  async (partnerStudentData) => {
    if (!partnerStudentData.id) {
      partnerStudentData.id = uuidv4();
    }
    const partnerStudentsRef = collection(db, "users");

    return await setDoc(doc(partnerStudentsRef, partnerStudentData.id), partnerStudentData, {
      merge: true,
    }).then((u) => {
      return {
        id: partnerStudentData.id,
        email: partnerStudentData.email,
      };
    });
  }
);

const initialState = {
  partnerStudent: undefined,
  partnerStudents: [],
  partnerSudentsByPrograms :[],
  isLoading: false,
  error: null,
};

const partnerStudentsSlice = createSlice({
  name: "partnerStudents",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnerStudents.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartnerStudents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partnerStudents = action.payload;
      })
      .addCase(fetchPartnerStudents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // .addCase(fetchPartnerStudentsByStatus.pending, (state) => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(fetchPartnerStudentsByStatus.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.partnerStudents = action.payload;
      // })
      // .addCase(fetchPartnerStudentsByStatus.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.error = action.error.message;
      // })
      .addCase(fetchPartnerStudent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartnerStudent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partnerStudent = action.payload;
      })
      .addCase(fetchPartnerStudent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPartnerStudentByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartnerStudentByEmail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchPartnerStudentByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setPartnerStudent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setPartnerStudent.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setPartnerStudent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(partnerStudentsActions.resetState, (state) => initialState);
  },
});

export default partnerStudentsSlice.reducer;
export const { actions: partnerStudentsActions } = partnerStudentsSlice;
