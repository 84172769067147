import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
);

const JobStatusChart = ({ data }) => {
  const options = {
    responsive: true,
    barThickness: 20,

    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  };

  return (
    <div className='w-full h-full'>
      <Bar data={data} options={options} />
    </div>
  );
};

export default JobStatusChart;
