import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  signUp,
  signInWithGoogle,
  // signInWithFacebook,
} from "../store/reducers/authSlice";
import { setUpUserAndNavigate } from "../misc/setUpUser";
import { handleFormInputChange } from "../misc/formUtils";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  const [isEmployer, setIsEmployer] = useState(false);
  const [isPartnerPage, setIsPartnerPage] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    setIsEmployer(path?.startsWith("/employer/"));
    setIsPartnerPage(path?.startsWith("/partner/"));
  }, [location.pathname]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleLoginGoogle = async () => {
    dispatch(signInWithGoogle()).then((authUser) => {
      handleLoginResponse(dispatch, authUser, navigate);
    });
  };

  const handleLoginResponse = (dispatch, authUser, navigate) => {
    if (!authUser.error) {
      setUpUserAndNavigate(dispatch, authUser, navigate);
    } else {
      const newErrors = { ...errors };
      newErrors.email = "";
      newErrors.password = "";
      newErrors.authError = "Invalid email or password. Please try again";
      setErrors(newErrors);
    }
  };

  const handleInputChange = (e) => {
    handleFormInputChange(e, setFormData, setErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      submitForm();
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.email || formData.email.trim() === "") {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
    } else {
      newErrors.email = "";
    }

    if (!formData.password || formData.password?.trim() === "") {
      newErrors.password = "Please enter a valid password.";
      valid = false;
    } else if (!isValidPassword(formData.password)) {
      valid = false;
      newErrors.password =
        "Password must be 8 characters long with at least one letter (uppercase or lowercase) and one digit.";
    } else {
      newErrors.password = "";
    }

    if (
      formData.confirmPassword !== formData.password ||
      !formData.confirmPassword
    ) {
      newErrors.confirmPassword =
        "Passwords do not match. Please enter matching passwords.";
      valid = false;
    } else {
      newErrors.confirmPassword = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const submitForm = async () => {
    let userCredentials = {
      email: formData.email,
      password: formData.password,
    };

    dispatch(signUp(userCredentials)).then((authUser) => {
      handleLoginResponse(dispatch, authUser, navigate);
    });

    setFormData({
      email: "",
      password: "",
      confirmPassword: "",
    });
  };

  const isValidPassword = (password) => {
    const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return pattern.test(password);
  };

  useEffect(() => {
    if (auth.error && auth.error?.code === "auth/email-already-in-use") {
      alert("Email already in use, please sign in");
    }
  }, [auth]);

  return (
    <>
      <section className='pt-100 login-register'>
        <div className='container'>
          <div className='row login-register-cover'>
            <div className='col-lg-4 col-md-6 col-sm-12 mx-auto'>
              <div className='text-center'>
                <p className='font-sm text-brand-2'>Register </p>
                <h2 className='mt-10 mb-5 text-brand-1'>
                  {isEmployer
                    ? "Employer "
                    : isPartnerPage
                    ? "Partner "
                    : "Member "}
                  Sign Up
                </h2>

                <button
                  onClick={handleLoginGoogle}
                  className='btn social-login hover-up mb-20'
                >
                  <img
                    src='/assets/imgs/template/icons/icon-google.svg'
                    alt='Sign In With Google'
                  />
                  <strong>Sign up with Google</strong>
                </button>
                <div className='divider-text-center'>
                  <span>Or continue with</span>
                </div>
              </div>

              <form
                className='login-register text-start mt-20'
                onSubmit={handleSubmit}
              >
                <div className='form-group'>
                  <label className='form-label' htmlFor='email'>
                    Email address
                  </label>
                  <input
                    className='form-control'
                    id='email'
                    name='email'
                    type='email'
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder='Email'
                  />
                </div>
                {errors.email && (
                  <div className='text-danger'>{errors.email}</div>
                )}

                <div className='form-group'>
                  <label className='form-label' htmlFor='password'>
                    Password *
                  </label>
                  <input
                    className='form-control'
                    id='password'
                    name='password'
                    type='password'
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder='************'
                  />
                </div>
                {errors.password && (
                  <div className='text-danger'>{errors.password}</div>
                )}
                <div className='form-group'>
                  <label className='form-label' htmlFor='confirmPassword'>
                    Re-Password *
                  </label>
                  <input
                    className='form-control'
                    id='confirmPassword'
                    name='confirmPassword'
                    type='password'
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder='************'
                  />
                </div>
                {errors.confirmPassword && (
                  <div className='text-danger'>{errors.confirmPassword}</div>
                )}

                <div className='form-group'>
                  <div className='form-group pt-20'>
                    <button
                      className='btn btn-brand-1 hover-up w-100 bg-[#05264E]'
                      type='submit'
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
                <div className='pt-10'>
                  Already have an account? &nbsp;
                  {isEmployer ? (
                    <Link
                      className='text-brand-2 font-weight-bold'
                      to='/employer/auth'
                    >
                      Sign in
                    </Link>
                  ) : isPartnerPage ? (
                    <Link
                      className='text-brand-2 font-weight-bold'
                      to='/partner/auth'
                    >
                      Sign in
                    </Link>
                  ) : (
                    <Link className='text-brand-2 font-weight-bold' to='/auth'>
                      Sign in
                    </Link>
                  )}
                </div>
                <div className='form-group d-flex justify-content-between'>
                  <label className=''>
                    <span className='text-small'>
                      By signing up you agree to our Terms of Service and
                      Privacy Policy.
                    </span>
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Register;
