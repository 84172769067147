import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="legal-content container section-full">
      <h1>Privacy Policy</h1>
      <p>
        At Assignments Hub, we are committed to protecting the privacy and security
        of our users' personal information. This Privacy Policy outlines our
        practices and policies for collecting, using, and disclosing information
        from our website visitors and users.
      </p>

      <h2>Collection of Information</h2>
      We may collect personal information such as your name, email address, and
      other contact information when you sign up for an account on Assignments Hub.
      We may also collect non-personal information such as your IP address, browser
      type, and device type.
      
      <h2>Use of Information</h2>
      We use your personal information to provide you with our services and to
      communicate with you about Assignments Hub-related matters. We may
      also use your information for research and analysis purposes, such as to
      improve our website and services.
      
      <h2>Disclosure of Information</h2>
      We do not sell or rent your personal information to third parties for
      marketing purposes. We may disclose your information to third-party service
      providers who help us with our website and services, such as hosting,
      analytics, and customer support. We may also disclose your information as
      required by law or in response to legal process.
      
      <h2>Security</h2>
      We take reasonable measures to protect your personal information from
      unauthorized access, use, or disclosure. However, no transmission of data over
      the internet is completely secure, and we cannot guarantee the security of
      your information.
      
      <h2>Cookies</h2>
      We may use cookies to enhance your experience on our website. Cookies are
      small data files that are stored on your device when you visit our website.
      You can disable cookies through your browser settings, but this may limit your
      ability to use certain features of our website.
      
      <h2>Links to Other Websites</h2>
      Our website may contain links to third-party websites. We are not responsible
      for the privacy practices or content of these websites. We encourage you to
      review the privacy policies of these websites before providing any personal
      information.
      
      <h2>Children's Privacy</h2>
      Our website is not directed to children under the age of 13. We do not
      knowingly collect personal information from children under the age of 13. If
      you are a parent or guardian and believe that your child has provided personal
      information on our website, please contact us immediately at
      privacy@codingandwriting.com.
      
      <h2>Changes to this Privacy Policy</h2>
      We may update this Privacy Policy from time to time. We will notify you of any
      material changes by posting the updated policy on our website. Your continued
      use of our website after the changes are posted will indicate your acceptance
      of the updated Privacy Policy. If you have any questions or concerns about
      this Privacy Policy or our practices, please contact us at
      privacy@codingandwriting.com.
    </div>
  );
};

export default PrivacyPolicy;
