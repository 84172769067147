import React from 'react';

const CookiePolicy = () => {
  return (
    <>
      <div className="legal-content container section-full">
        <h1>Cookie Policy</h1>
        <p>
          At Assignments Hub, we value your privacy and are committed to being transparent about the use of cookies on our website. This Cookie Policy explains how we use cookies and similar technologies on our website and your choices regarding these technologies.
        </p>
        <h2>What are cookies?</h2>
        <p>
          Cookies are small text files that are stored on your device when you visit a website. They are commonly used to make websites work more efficiently and to provide website owners with information about how visitors interact with their website.
        </p>
        <h2>How we use cookies</h2>
        <p>We use cookies on our website for the following purposes:</p>

        <ul>
          <li>
            Essential cookies: These cookies are necessary for our website to function properly. They enable you to navigate our website, use its features, and access secure areas.
          </li>
          <li>
            Analytics cookies: These cookies collect information about how visitors use our website, such as which pages they visit most frequently and if they encounter any errors. This information helps us improve our website's performance and usability.
          </li>
          <li>
            Advertising cookies: We may use these cookies to display relevant advertisements based on your browsing history and interests. They also assist us in measuring the effectiveness of our advertising campaigns.
          </li>
          <li>
            Social media cookies: These cookies enable you to share content from our website on social media platforms like Facebook and Twitter.
          </li>
        </ul>

        <h2>Your choices regarding cookies</h2>
        <p>
          You can manage the use of cookies on our website through your browser settings. Most browsers allow you to block or delete cookies. However, please note that doing so may affect your experience on our website.
        </p>
        <p>
          We also use a cookie consent tool to obtain your consent for the use of non-essential cookies. You can choose to accept or reject these cookies.
        </p>

        <h2>Changes to this Cookie Policy</h2>
        <p>
          We may update this Cookie Policy periodically. In case of significant changes, we will notify you by posting the updated policy on our website.
        </p>

        <h2>Contact us</h2>
        <p>
          If you have any questions about this Cookie Policy or our use of cookies, please feel free to contact us at privacy@codingandwriting.com.
        </p>
      </div>
    </>
  );
};

export default CookiePolicy;
