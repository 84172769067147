import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authActions } from "../../store/reducers/authSlice";
import { signOut } from "firebase/auth";
import { partnersActions } from "../../store/reducers/partnerSlice";
import { usersActions } from "../../store/reducers/usersSlice";

const Sidebar = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem("isLoggedIn")
  const handleToggle = () => {
    
    console.log('props', props);
    props.onClose();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSignOut = () => {
    dispatch(signOut()).then(() => {
      dispatch(authActions.resetState());
      dispatch(partnersActions.resetState());
      dispatch(usersActions.resetState());
      localStorage.setItem("isLoggedIn",false)
      localStorage.removeItem("userId")
      navigate("/");
    });
  };

  return (
    <>
      <div
        className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${props.openClass}`}
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="mobile-menu-wrap mobile-header-border">
              {/* mobile menu start*/}
              <nav>
                <ul className="mobile-menu font-heading">
                  <li>
                    <span onClick={handleToggle}>
                      <Link to="/">Home</Link>
                    </span>
                  </li>
                  <li>
                    <span onClick={handleToggle}>
                      <Link to="/about-us">About Us</Link>
                    </span>
                  </li>
                  <li>
                    <span onClick={handleToggle}>
                      <Link to="/contact-us">Contact Us</Link>
                    </span>
                  </li>
                
                  {/* <li
                      className={
                        isActive.key === 3
                          ? "has-children active"
                          : "has-children"
                      }
                    >
                      <span
                        onClick={() => handleToggle(3)}
                        className="menu-expand"
                      ></span> */}

                  {/* <Link to="/auth">Your jobs</Link> */}
                  {/* </li> */}
                  {/* <li
                      className={
                        isActive.key === 3
                          ? "has-children active"
                          : "has-children"
                      }
                    >
                      <span
                        onClick={() => handleToggle(3)}
                        className="menu-expand"
                      >
                        <i className="fi-rr-angle-small-down"></i>
                      </span>

                      <Link to="/companies-grid">Recruiters</Link>

                      <ul
                        className={
                          isActive.key === 3
                            ? "sub-menu d-block"
                            : "sub-menu d-none"
                        }
                      >
                        <li>
                          <Link to="/companies-grid">Recruiters</Link>
                        </li>
                        <li>
                          <Link to="/company-details">Company Details</Link>
                        </li>
                      </ul>
                    </li> */}

                  {/* <li
                      className={
                        isActive.key === 6
                          ? "has-children active"
                          : "has-children"
                      }
                    >
                      <span
                        onClick={() => handleToggle(6)}
                        className="menu-expand"
                      >
                        <i className="fi-rr-angle-small-down"></i>
                      </span>

                      <Link to="/blog-grid">Blog</Link>

                      <ul
                        className={
                          isActive.key === 6
                            ? "sub-menu d-block"
                            : "sub-menu d-none"
                        }
                      >
                        <li>
                          <Link to="/blog-grid">Blog Grid</Link>
                        </li>
                        <li>
                          <Link to="/blog-grid-2">Blog Grid 2</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">Blog Single</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/page-contact">Contact</Link>
                    </li> */}
                </ul>
              </nav>
            </div>
        
            {/* <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link to="#">Profile</Link>
                  </li>
                  <li>
                    <Link to="#">Work Preferences</Link>
                  </li>
                  <li>
                    <Link to="#">Account Settings</Link>
                  </li>
                  <li>
                    <Link to="#">Go Pro</Link>
                  </li>
                  <li>
                    <Link to="/page-signin">Sign Out</Link>
                  </li>
                </ul>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
