// usersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";

const setUserDoc = (userDoc) => {
  let user = undefined;
  
  console.log(userDoc, "userDocuserDocuserDoc");
  if (userDoc.exists()) {
    user = {
      id: userDoc.data().id,
      email: userDoc.data().email,
      name: userDoc.data().name,
      photoUrl: userDoc.data().photoUrl,
      partnerId: userDoc.data().partnerId,
      employerId: userDoc.data().employerId,
      skill: userDoc.data().skill,
      bio: userDoc.data().bio,
      contactNumber: userDoc.data().contactNumber,
      state: userDoc.data().state,
      city: userDoc.data().city,
      zipCode: userDoc.data().zipCode,
      addressLine1: userDoc.data().addressLine1,
      addressLine2: userDoc.data().addressLine2,
      isPartnerAdmin: userDoc.data().isPartnerAdmin,
      program: userDoc.data().program,
      isEmployer: userDoc.data().isEmployer,
      isParnter: userDoc.data().isParnter,
    };
  }

  return user;
};

export const fetchUsers= createAsyncThunk(
  "users/fetchUsers",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "users"), where("id", "in", ids));
    } else {
      tempcollection = collection(db, "users");
    }

    const querySnapshot = await getDocs(tempcollection);

    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users;
  }
);
export const fetchUsersByEmployerIds= createAsyncThunk(
  "users/fetchUsersByEmployerIds",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "users"), where("employerId", "in", ids));
    } else {
      tempcollection = []
    }

    const querySnapshot = await getDocs(tempcollection);

    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users;
  }
);

export const fetchUser = createAsyncThunk("users/fetchUser", async (userId) => {
  const userDoc = await getDoc(doc(db, "users", userId));
  return setUserDoc(userDoc);
});

export const fetchUserByEmail = createAsyncThunk(
  "users/fetchUserByEmail",
  async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));

    const querySnapshot = await getDocs(q);
    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users[0];
  }
);

export const setUser = createAsyncThunk("users/setUser", async (userData) => {
  if (!userData.id) {
    userData.id = uuidv4();
  }
  const usersRef = collection(db, "users");

  return await setDoc(doc(usersRef, userData.id), userData, {
    merge: true,
  }).then((u) => {
    return {
      id: userData.id,
      email: userData.email,
    };
  });
});

const initialState = {
  user: undefined,
  users: [],
  employers : [],
  isLoading: false,
  error: null,

};
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUsersByEmployerIds.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsersByEmployerIds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employers = action.payload;
      })
      .addCase(fetchUsersByEmployerIds.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserByEmail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchUserByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(usersActions.resetState, (state) => initialState);
  },
});

export default usersSlice.reducer;
export const { actions: usersActions } = usersSlice;
