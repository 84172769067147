import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";



// const sendEmail = async (formData) => {
//   const url = 'https://us-central1-just-easy-jobs-50739.cloudfunctions.net/sendEmail';
//   const data = formData

//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     });

//     if (response.ok) {
//       const result = await response.text();
//       console.log('Email sent successfully:', result);
//     } else {
//       console.error('Failed to send email:', response.statusText);
//     }
//   } catch (error) {
//     console.error('Error sending email:', error);
//   }
// };
const setTaskFormDataDoc = (taskFormDataDoc) => {
  let taskFormData = undefined;

  if (taskFormDataDoc.exists()) {
    taskFormData = {
      id: taskFormDataDoc.data().id,
      name: taskFormDataDoc.data().name,
      email: taskFormDataDoc.data().email,
      phone: taskFormDataDoc.data().phone,
      deadline: taskFormDataDoc.data().deadline,
      subject: taskFormDataDoc.data().subject,
      filePath: taskFormDataDoc.data().file_path,
    };
  }

  return taskFormData;
};

export const fetchTaskFormDatas = createAsyncThunk("taskFormData/fetchTaskFormDatas", async (ids = undefined) => {
  let tempcollection;
  if (ids && ids.length > 0) {
    tempcollection = query(collection(db, "task-form-data"), where("id", "in", ids));
  } else {
    tempcollection = collection(db, "task-form-data");
  }

  const querySnapshot = await getDocs(tempcollection);

  let taskFormDatas = querySnapshot.docs.map((doc) => setTaskFormDataDoc(doc));
  return taskFormDatas;
});

export const fetchTaskFormData = createAsyncThunk("taskFormData/fetchTaskFormData", async (taskFormDataId) => {
  const taskFormDataDoc = await getDoc(doc(db, "task-form-data", taskFormDataId));
  return setTaskFormDataDoc(taskFormDataDoc);
});

export const setTaskFormData = createAsyncThunk("task-form-data/setTaskFormData", async (taskFormData, thunkAPI) => {
  if (!taskFormData.id) {
    taskFormData.id = uuidv4();
  }
  console.log('data to be submitted', taskFormData)
  const taskFormDatasRef = collection(db, "task-form-data");

  try {
    await setDoc(doc(taskFormDatasRef, taskFormData.id), taskFormData, {
      merge: true,
    });

    // await sendEmail(taskFormData)
    return {
      id: taskFormData.id,
    };
  } catch (error) {
    console.log('error.message' , error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
});

const initialState = {
  taskFormData: undefined,
  taskFormDatas: [],
  isLoading: false,
  error: null,
};

const taskFormDataSlice = createSlice({
  name: "taskFormData",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskFormDatas.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTaskFormDatas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.taskFormDatas = action.payload;
      })
      .addCase(fetchTaskFormDatas.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTaskFormData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTaskFormData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.taskFormData = action.payload;
      })
      .addCase(fetchTaskFormData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setTaskFormData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setTaskFormData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setTaskFormData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(  taskFormDataActions.resetState, (state) => initialState);
  },
});

export default taskFormDataSlice.reducer;
export const { actions: taskFormDataActions } = taskFormDataSlice;
