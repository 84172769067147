import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, setUser } from "../store/reducers/usersSlice";
import { fetchPartners } from "../store/reducers/partnerSlice";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../services/firebase";
import { handleFormInputChange } from "../misc/formUtils";
import { createJob, getJobsByEmail } from "../store/reducers/jobsSlice";
import { Col, Row } from "react-bootstrap";

const EmployerDashboard = () => {
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state);
  console.log(jobs.allJobs, "jobsjobsjobsjobs");
  const docID = localStorage.getItem("id");
  const [partnersState, setPartnersState] = useState([]);
  const [activeIndex, setActiveIndex] = useState(2);
  const [inputValue, setInputValue] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [AlljobsData, setAllJobsData] = useState([]);
  let employerEmail = JSON.parse(localStorage.getItem("auth"));
  console.log(employerEmail, "employerEmailemployerEmail");
  // create job fields
  const [addJob, setJob] = useState({
    addressLine1: "",
    addressLine2: "",
    businessName: "",
    city: "",
    contactName: "",
    contactNumber: "",
    country: "",
    dateCreated: "",
    dateUpdated: "",
    days: [],
    hours: 0,
    id: "",
    isActive: true,
    isClone: true,
    language: "",
    jobDescription: "",
    owner: "",
    pay: "",
    shiftDescription: [],
    sortOrder: "",
    state: "",
    title: "",
    type: "",
    zipCode: "",
  });

  // addJob errors
  const [addJobErros, setAddJobErrors] = useState({});

  // fetch school partners
  useEffect(() => {
    dispatch(fetchPartners()).then((x) => {
      console.log("partners1", x);
      setPartnersState(x.payload);
    });
  }, [dispatch]);

  // set profile data in state
  useEffect(() => {
    console.log(docID, "userIduserIduserIduserId");
    if (docID) {
      console.log("fetchUser", docID);
      dispatch(fetchUser(docID)).then((x) => {
        console.log("fetched user", x.payload);
        if (x.payload) {
          console.log("useradfasdf", x.payload);
          setFormData({
            name: x.payload?.name ?? "",
            email: x.payload?.email ?? "",
            contactNumber: x.payload?.contactNumber ?? "",
            bio: x.payload?.bio ?? "",
            addressLine1: x.payload?.addressLine1 ?? "",
            addressLine2: x.payload?.addressLine2 ?? "",
            country: x.payload?.country ?? "USA",
            state: x.payload?.state ?? "",
            city: x.payload?.city ?? "",
            zipCode: x.payload?.zipCode ?? "",
            photoUrl: x.payload?.photoUrl ?? "",
            partnerId: x.payload.partnerId ?? "",
            skills: x.payload.skills?.split(",")?.map((x) => x.trim()),
          });
        }
      });
    }
  }, [dispatch, docID]);

  useEffect(() => {
    if (
      jobs.allJobs !== null &&
      jobs.allJobs !== undefined &&
      jobs.allJobs.length > 0
    ) {
      setAllJobsData(jobs.allJobs);
    }
  }, [jobs.allJobs]);
  console.log(AlljobsData, "AlljobsDataAlljobsDataAlljobsDataAlljobsData");
  // set view with index
  const handleOnClick = (index) => {
    setActiveIndex(index); // remove the curly braces
  };

  // upload profile picture
  function handleAvatarUpload(event) {
    const file = event.target.files[0];
    setAvatar(file);
  }

  // delete profile picture
  function handleDeleteAvatar() {
    setAvatar(null);
  }

  // profile data
  const [formData, setFormData] = useState({
    addressLine1: "",
    addressLine2: "",
    businessName: "",
    city: "",
    contactName: "",
    contactNumber: "",
    country: "",
    dateCreated: "",
    dateUpdated: "",
    days: [],
    employeeId: "",
    hours: 0,
    id: "",
    isActive: true,
    isClone: true,
    language: "",
    longDescription: "",
    owner: "",
    pay: "",
    photoUrl: "",
    shiftDescription: [],
    sortOrder: "",
    state: "",
    title: "",
    type: "",
    zipCode: "",
  });

  // profiel data erros
  const [formErrors, setErrors] = useState({});

  // My Profile handle Submitter
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!(event.nativeEvent?.submitter?.id === "btn-submit")) {
      return;
    }

    // Perform form validation
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      // Form is valid, perform desired actions
      let photoUrl;
      if (avatar) {
        const storageRef = ref(
          storage,
          "/user-display-pictures/" + avatar.name
        );
        await uploadBytes(storageRef, avatar);
        photoUrl = await getDownloadURL(storageRef);
      }

      let user = {
        id: docID,
        addressLine1: formData.addressLine1 ?? "",
        addressLine2: formData.addressLine2 ?? "",
        bio: formData.bio ?? "",
        city: formData.city ?? "",
        contactNumber: formData.contactNumber ?? "",
        country: formData.country ?? "",
        email: formData.email ?? "",
        name: formData.name ?? "",
        partnerId: formData.partnerId ?? "",
        skills: formData.skills?.split(",")?.map((x) => x.trim()) ?? [],
        state: formData.state ?? "",
        zipCode: formData.zipCode ?? "",
      };
      if (photoUrl) {
        user.photoUrl = photoUrl;
      }
      await dispatch(setUser(user)).then((x) => {
        console.log("setUser", x);
      });
      // navigate(`/partner?partnerId=${user.partnerId}&userId=${docID}`);
    } else {
      // Form has validation errors, update the formErrors state
      setErrors(errors);
    }
  };

  // My Profile Data Validate Form
  function validateForm(formData) {
    console.log(formData, "daadsadasd");
    const errors = {};

    if (!formData.title.trim()) {
      errors.title = "Job title is required";
    }

    if (!formData.pay.trim()) {
      errors.pay = "Pay is required";
    }

    if (!formData.contactName.trim()) {
      errors.contactName = "Contact name is required";
    }

    if (!formData.contactNumber.trim()) {
      errors.contact = "Contact is required";
    }

    if (!formData.businessName.trim()) {
      errors.businessName = "Businesses Name is required";
    }

    if (!formData.addressLine1.trim()) {
      errors.addressLine1 = "Address is required";
    }

    if (!formData.state.trim()) {
      errors.state = "State is required";
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
    }

    if (!formData.zipCode.trim()) {
      errors.zipCode = "Zip Code is required";
    }

    if (!formData.type.trim()) {
      errors.businessType = "Business Type is required";
    }

    if (!formData.longDescription.trim()) {
      errors.longDescription = "Job Description is required";
    }
    if (!formData.country.trim()) {
      errors.country = "Country Name is required";
    }

    if (formData.days.length === 0) {
      errors.days = "Days is required";
    }

    if (formData.shiftDescription.length === 0) {
      errors.shiftDescription = "Shift description is required";
    }
    // Validate other fields as needed

    return errors;
  }

  const handleInputChange = (e) => {
    handleFormInputChange(e, setFormData, setErrors);
  };

  // Function to handle button click
  const handleSkillClick = (skill) => {
    if (!selectedSkills.includes(skill)) {
      setSelectedSkills([...selectedSkills, skill]);
      setInputValue((prevValue) =>
        prevValue === "" ? skill : `${prevValue}, ${skill}`
      );
    }
  };

  // create job change handler
  const changeHandlerCreateJob = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "changeHandlerCreateJobchangeHandlerCreateJob");
    setJob({
      ...addJob,
      [name]: value,
    });
  };

  // create job handle submit
  const handleSubmitCreateJob = async (event) => {
    event.preventDefault();

    const errors = addJobvalidateForm(addJob);
    if (Object.keys(errors).length !== 0) {
      setAddJobErrors(errors);
      console.log(errors);
      return;
    }

    let job = {
      addressLine1: addJob.addressLine1,
      addressLine2: addJob.addressLine2,
      businessName: addJob.businessName,
      city: addJob.city,
      contactName: addJob.contactName,
      contactNumber: addJob.contactNumber,
      dateCreated: new Date(),
      dateUpdated: "",
      days: addJob.days,
      hours: addJob.hours,
      isActive: true,
      isClone: true,
      language: addJob.language,
      JobDescription: addJob.jobDescription,
      pay: addJob.pay,
      shiftDescription: addJob.shiftDescription,
      sortOrder: addJob.sortOrder,
      state: addJob.state,
      title: addJob.title,
      type: addJob.type,
      zipCode: addJob.zipCode,
      email: employerEmail.email,
    };
    dispatch(createJob({ job, setActiveIndex }))
      .then((createjobsresponse) => {
        dispatch(getJobsByEmail(job.email));
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    dispatch(getJobsByEmail(employerEmail.email));
  }, [dispatch, employerEmail.email]);
  // Shift Change Handler
  const shiftsChangeHandler = (e) => {
    let { name, value } = e.target;
    console.log(name, value);
    try {
      let findisAlreadyExist = addJob.shiftDescription.findIndex(
        (data, index) => data === value
      );
      if (name === "Shift") {
        if (findisAlreadyExist === -1) {
          setJob({
            ...addJob,
            shiftDescription: [...addJob.shiftDescription, value],
          });
        } else {
          addJob.shiftDescription.splice(findisAlreadyExist, 1);
          setJob(formData);
        }
      }
    } catch (error) {}
  };

  // Days Change Handler
  const daysChangeHandler = (e) => {
    let { name, value } = e.target;
    try {
      let findisAlreadyExist = addJob.days.findIndex(
        (data, index) => data === value
      );
      if (name === "day") {
        if (findisAlreadyExist === -1) {
          setJob({
            ...addJob,
            days: [...addJob.days, value],
          });
        } else {
          addJob.days.splice(findisAlreadyExist, 1);
          setJob(addJob);
        }
      } else {
      }
    } catch (error) {}
  };

  // add Job form validate
  const addJobvalidateForm = (addJob) => {
    console.log(addJob, "daadsadasd");
    const errors = {};

    if (!addJob.title.trim()) {
      errors.title = "Job title is required";
    }

    if (!addJob.pay.trim()) {
      errors.pay = "Pay is required";
    }

    if (!addJob.contactName.trim()) {
      errors.contactName = "Contact Name is required";
    }

    if (!addJob.contactNumber.trim()) {
      errors.contact = "Contact is required";
    }

    if (!addJob.businessName.trim()) {
      errors.businessName = "Business Name is required";
    }

    if (!addJob.addressLine1.trim()) {
      errors.addressLine1 = "Address 1 is required";
    }
    if (!addJob.addressLine2.trim()) {
      errors.addressLine2 = "Address 2 is required";
    }
    if (!addJob.state.trim()) {
      errors.state = "State is required";
    }

    if (!addJob.city.trim()) {
      errors.city = "City is required";
    }

    if (!addJob.zipCode.trim()) {
      errors.zipCode = "Zip Code is required";
    }

    if (!addJob.type.trim()) {
      errors.businessType = "Business Type is required";
    }

    if (!addJob.jobDescription.trim()) {
      errors.jobDescription = "Job Description is required";
    }

    if (addJob.days.length === 0) {
      errors.days = "Days is required";
    }

    if (addJob.shiftDescription.length === 0) {
      errors.shiftDescription = "Shift description is required";
    }
    // Validate other fields as needed

    return errors;
  };
  console.log("addJobaddJobaddJobaddJob", addJob);
  return (
    <>
      <div>
     

        <section className="section-box mt-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="box-nav-tabs nav-tavs-profile mb-5">
                  <ul className="nav" role="tablist">
                    <li>
                      <Link
                        className={
                          activeIndex === 1
                            ? "btn btn-border aboutus-icon mb-20 active"
                            : "btn btn-border aboutus-icon mb-20"
                        }
                        onClick={() => handleOnClick(1)}
                      >
                        Employer Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          activeIndex === 2
                            ? "btn btn-border recruitment-icon mb-20 active"
                            : "btn btn-border recruitment-icon mb-20"
                        }
                        onClick={() => handleOnClick(2)}
                      >
                        Your Jobs
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          activeIndex === 3
                            ? "btn btn-border people-icon mb-20 active"
                            : "btn btn-border people-icon mb-20"
                        }
                        onClick={() => handleOnClick(3)}
                      >
                        Add Jobs
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-12 col-12 mb-50">
                <div className="content-single">
                  <div className="tab-content">
                    {/* Tab - 1 : My Profile */}
                    <div
                      className={`tab-pane fade ${
                        activeIndex === 1 ? "show active" : ""
                      }`}
                    >
                      <h3 className="mt-0 mb-15 color-brand-1">Employer Profile</h3>
                      <Link to="#" className={`font-md color-text-paragraph-2`}>
                        Update your profile
                      </Link>

                      <div className="mt-35 mb-40 box-info-profie">
                        <div className="image-profile">
                          <img
                            src={
                              avatar
                                ? URL.createObjectURL(avatar)
                                : "/assets/imgs/avatar/ava_dp.png"
                            }
                            alt="display avatar"
                          />
                        </div>
                        <label
                          htmlFor="avatar-upload"
                          className="btn btn-apply"
                        >
                          Upload
                        </label>
                        <button
                          className="btn btn-link"
                          onClick={handleDeleteAvatar}
                        >
                          Delete
                        </button>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="row form-contact">
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleAvatarUpload}
                                style={{ display: "none" }}
                                id="avatar-upload"
                                name="photoUrl"
                                value={formData.photoUrl}
                              />
                            </div>
                            <div className="form-group">
                              <label className="font-sm color-text-mutted mb-10">
                                Full Name *
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Steven Job"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                            </div>
                            {formErrors.name && (
                              <div className="text-danger">
                                {formErrors.name}
                              </div>
                            )}
                            <div className="form-group mt-10">
                              <label className="font-sm color-text-mutted mb-10">
                                Email *
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                placeholder="stevenjob@gmail.com"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                              />
                            </div>
                            {formErrors.email && (
                              <div className="text-danger">
                                {formErrors.email}
                              </div>
                            )}
                            <div className="form-group mt-10">
                              <label className="font-sm color-text-mutted mb-10">
                                Contact number
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="(888) 888 8888"
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleInputChange}
                              />
                            </div>
                            {formErrors.contactNumber && (
                              <div className="text-danger">
                                {formErrors.contactNumber}
                              </div>
                            )}

                            <div className="form-group mt-10">
                              <label className="font-sm color-text-mutted mb-10">
                                School
                              </label>

                              <select
                                className="form-control"
                                name="partnerId"
                                value={formData.partnerId}
                                onChange={handleInputChange}
                              >
                                <option key="" value="">
                                  Select a school
                                </option>
                                {partnersState &&
                                  partnersState.map((partner) => (
                                    <option key={partner.id} value={partner.id}>
                                      {partner.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            {formErrors.partnerId && (
                              <div className="text-danger">
                                {formErrors.partnerId}
                              </div>
                            )}
                            <div className="form-group mt-10">
                              <label className="font-sm color-text-mutted mb-10">
                                Bio
                              </label>
                              <textarea
                                className="form-control"
                                name="bio"
                                value={formData.bio}
                                onChange={handleInputChange}
                                placeholder=""
                              />
                            </div>
                            {formErrors.bio && (
                              <div className="text-danger">
                                {formErrors.bio}
                              </div>
                            )}
                            <div className="form-group mt-10">
                              <label className="font-sm color-text-mutted mb-10">
                                Address Line 1
                              </label>
                              <input
                                className="form-control"
                                name="addressLine1"
                                value={formData.addressLine1}
                                onChange={handleInputChange}
                                placeholder="1234 Main St"
                              />
                            </div>
                            {formErrors.addressLine1 && (
                              <div className="text-danger">
                                {formErrors.addressLine1}
                              </div>
                            )}
                            <div className="form-group mt-10">
                              <label className="font-sm color-text-mutted mb-10">
                                Address Line 2
                              </label>
                              <textarea
                                className="form-control"
                                name="addressLine1"
                                value={formData.addressLine1}
                                onChange={handleInputChange}
                                placeholder=""
                              />
                            </div>

                            <div className="row">
                              <div className="col-lg-6 mt-10">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    City
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Mcallen"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {formErrors.city && (
                                  <div className="text-danger">
                                    {formErrors.city}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 mt-10">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    State
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="New York"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {formErrors.state && (
                                  <div className="text-danger">
                                    {formErrors.state}
                                  </div>
                                )}
                              </div>

                              <div className="col-lg-6 mt-10">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Zip code
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="82356"
                                    name="zipCode"
                                    value={formData.zipCode}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {formErrors.zipCode && (
                                  <div className="text-danger">
                                    {formErrors.zipCode}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 mt-10">
                                <div className="form-group">
                                  <label className="font-sm color-text-mutted mb-10">
                                    Country
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="United States"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {formErrors.country && (
                                  <div className="text-danger">
                                    {formErrors.country}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="box-button mt-15">
                              <button
                                id="btn-submit"
                                className="btn btn-apply-big font-md font-bold"
                                type="submit"
                              >
                                Save All Changes
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="box-skills">
                              <h5 className="mt-0 color-brand-1">
                                Skills (comma delimitted)
                              </h5>
                              <div className="form-contact">
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="E.g. Team Work, Task Management, Communication"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="box-tags mt-30">
                                <button
                                  className="btn btn-grey-small mr-10"
                                  onClick={() => handleSkillClick("Team Work")}
                                >
                                  Team Work
                                </button>

                                <button
                                  className="btn btn-grey-small mr-10"
                                  onClick={() =>
                                    handleSkillClick("Communication")
                                  }
                                >
                                  Communication
                                </button>

                                <button
                                  className="btn btn-grey-small mr-10"
                                  onClick={() =>
                                    handleSkillClick("Cash Handling")
                                  }
                                >
                                  Cash Handling
                                </button>

                                <button
                                  className="btn btn-grey-small mr-10"
                                  onClick={() =>
                                    handleSkillClick("Problem Solving")
                                  }
                                >
                                  Problem Solving
                                </button>

                                <button
                                  className="btn btn-grey-small mr-10"
                                  onClick={() =>
                                    handleSkillClick("Attention to detail")
                                  }
                                >
                                  Attention to detail
                                </button>

                                <button
                                  className="btn btn-grey-small mr-10"
                                  onClick={() =>
                                    handleSkillClick("Multitasking")
                                  }
                                >
                                  Multitasking
                                </button>

                                <button
                                  className="btn btn-grey-small mr-10"
                                  onClick={() =>
                                    handleSkillClick("Time Management")
                                  }
                                >
                                  Time Management
                                </button>
                              </div>
                              <div className="mt-40">
                                <span className="card-info font-sm color-text-paragraph-2">
                                  You can add up to 15 skills
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* Tab - 2 : Your jObs */}
                    <div
                      className={`tab-pane fade ${
                        activeIndex === 2 && "show active"
                      }`}
                    >
                      <h3 className="mt-0 color-brand-1 mb-50">My Jobs</h3>
                      {AlljobsData.length > 0 ? " " : " "}
                      <div className="row display-list">
                        {AlljobsData.length > 0 ? (
                          AlljobsData.map((data, index) => {
                            return (
                              <div className="col-xl-12 col-12">
                                <div className="card-grid-2 ">
                                  <span className="flash" />
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="card-grid-2-image-left">
                                        <div className="image-box">
                                          <img
                                            src="assets/imgs/brands/brand-5.png"
                                            alt="jobBox"
                                          />
                                        </div>
                                        <div className="right-info">
                                          <span className="location-small">
                                            New York, US
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 text-start text-md-end pr-60 col-md-6 col-sm-12"></div>
                                  </div>
                                  <div className="card-block-info">
                                    <div className="mt-5">
                                      <span className="card-briefcase">
                                        Fulltime
                                      </span>
                                      <span className="card-time">
                                        <span>4</span>
                                        <span> mins ago</span>
                                      </span>
                                    </div>
                                    <p className="font-sm color-text-paragraph mt-10">
                                      {data.JobDescription}
                                    </p>
                                    <div className="card-2-bottom mt-20">
                                      <div className="row">
                                        <div className="col-lg-7 col-7">
                                          <span className="card-text-price">
                                            ${data.pay}
                                          </span>
                                          <span className="text-muted">
                                            /Hour
                                          </span>
                                        </div>
                                        <div className="col-lg-5 col-5  d-flex justify-content-end gap-4 ">
                                          <div
                                            className="btn "
                                            data-bs-toggle="modal"
                                            data-bs-target="#ModalApplyJobForm"
                                          >
                                            Delete
                                          </div>
                                          <div
                                            className="btn "
                                            data-bs-toggle="modal"
                                            data-bs-target="#ModalApplyJobForm"
                                          >
                                            Update
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <Row>
                            <Col
                              sm={12}
                              md={12}
                              lg={12}
                              className="text-center"
                            >
                              {"No Jobs Data"}
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                    {/* Tab - 3 : Create Job */}
                    <div
                      className={`tab-pane fade ${
                        activeIndex === 3 && "show active"
                      }`}
                    >
                      <h3 className="mt-0 color-brand-1 mb-50">Create Job</h3>
                      <div className="row">
                        <form onSubmit={handleSubmitCreateJob}>
                          <div className="form-group">
                            <label htmlFor="title">Job title</label>
                            <input
                              type="input"
                              id="title"
                              name="title"
                              placeholder="Job title"
                              className="form-control"
                              value={addJob.title}
                              onChange={changeHandlerCreateJob}
                              required
                            />
                            {addJobErros.title && (
                              <div className="text-danger">
                                {addJobErros.title}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="contact">Contact </label>
                            <input
                              id="contact"
                              type="number"
                              name="contactNumber"
                              placeholder="(XXX) XXX-XXXX"
                              className="form-control"
                              value={addJob.contactNumber}
                              onChange={changeHandlerCreateJob}
                              required
                            ></input>
                            {addJobErros.contact && (
                              <div className="text-danger">
                                {addJobErros.contact}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="contact">Contact Name </label>
                            <input
                              id="contact"
                              name="contactName"
                              placeholder="Contact Name"
                              className="form-control"
                              value={addJob.contactName}
                              onChange={changeHandlerCreateJob}
                              rows="3"
                              required
                            ></input>
                            {addJobErros.contactName && (
                              <div className="text-danger">
                                {addJobErros.contactName}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="businessName">Business Name</label>
                            <input
                              type="input"
                              id="businessName"
                              name="businessName"
                              placeholder="Business Name"
                              className="form-control"
                              value={addJob.businessName}
                              onChange={changeHandlerCreateJob}
                              required
                            />
                            {addJobErros.businessName && (
                              <div className="text-danger">
                                {addJobErros.businessName}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="addressLine1">Address line 1</label>
                            <input
                              type="input"
                              id="addressLine1"
                              name="addressLine1"
                              placeholder="Address line 1"
                              className="form-control"
                              value={addJob.addressLine1}
                              onChange={changeHandlerCreateJob}
                              required
                            />
                            {addJobErros.addressLine1 && (
                              <div className="text-danger">
                                {addJobErros.addressLine1}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="addressLine2">Address line 2</label>
                            <input
                              type="input"
                              id="addressLine2"
                              name="addressLine2"
                              placeholder="Address line 2"
                              className="form-control"
                              value={addJob.addressLine2}
                              onChange={changeHandlerCreateJob}
                            />
                            {addJobErros.addressLine2 && (
                              <div className="text-danger">
                                {addJobErros.addressLine2}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input
                              type="input"
                              id="city"
                              name="city"
                              placeholder="City"
                              className="form-control"
                              value={addJob.city}
                              onChange={changeHandlerCreateJob}
                              required
                            />
                            {addJobErros.city && (
                              <div className="text-danger">
                                {addJobErros.city}
                              </div>
                            )}
                          </div>

                          <div className="form-group state-city">
                            <div>
                              <label htmlFor="state">State</label>
                              <select
                                id="state"
                                name="state"
                                className="form-control"
                                value={addJob.state}
                                onChange={changeHandlerCreateJob}
                                required
                              >
                                <option value="">Select State</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AL">Alabama</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                              {addJobErros.state && (
                                <div className="text-danger">
                                  {addJobErros.state}
                                </div>
                              )}
                            </div>

                            <div>
                              <label htmlFor="zipCode">Zip code</label>
                              <input
                                type="input"
                                id="zipCode"
                                name="zipCode"
                                placeholder="Zip code"
                                className="form-control"
                                value={addJob.zipCode}
                                onChange={changeHandlerCreateJob}
                                required
                              />
                              {addJobErros.zipCode && (
                                <div className="text-danger">
                                  {addJobErros.zipCode}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="businessType">Business type</label>
                            <input
                              type="input"
                              id="businessType"
                              name="type"
                              placeholder="Business type"
                              className="form-control"
                              value={addJob.type}
                              onChange={changeHandlerCreateJob}
                              required
                            />
                            {addJobErros.businessType && (
                              <div className="text-danger">
                                {addJobErros.businessType}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="pay">Pay (Optional)</label>
                            <input
                              type="input"
                              id="pay"
                              name="pay"
                              placeholder="Payment details or negotiable"
                              className="form-control"
                              value={addJob.pay}
                              onChange={changeHandlerCreateJob}
                            />
                            {addJobErros.pay && (
                              <div className="text-danger">
                                {addJobErros.pay}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="shifts">Shifts:</label>
                            <div className="checkbox-group">
                              <div className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="Shift"
                                  value="Morning"
                                  onChange={shiftsChangeHandler}
                                />{" "}
                                <label> Morning</label>
                              </div>
                              <div className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="Shift"
                                  value="Afternoon"
                                  onChange={shiftsChangeHandler}
                                />{" "}
                                <label>Afternoon</label>
                              </div>

                              <div className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="Shift"
                                  value="Evening"
                                  onChange={shiftsChangeHandler}
                                />{" "}
                                <label>Evening</label>
                              </div>

                              <div className="d-flex align-items-center gap-2 ">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="Shift"
                                  value="Night"
                                  onChange={shiftsChangeHandler}
                                />{" "}
                                <label>Night</label>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="days">Days:</label>
                            <div className="checkbox-group">
                              <label className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="day"
                                  value="Sunday"
                                  onChange={daysChangeHandler}
                                />{" "}
                                Sunday
                              </label>
                              <label className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="day"
                                  value="Monday"
                                  onChange={daysChangeHandler}
                                />{" "}
                                Monday
                              </label>
                              <label className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="day"
                                  value="Tuesday"
                                  onChange={daysChangeHandler}
                                />{" "}
                                Tuesday
                              </label>
                              <label className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="day"
                                  value="Wednesday"
                                  onChange={daysChangeHandler}
                                />{" "}
                                Wednesday
                              </label>
                              <label className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="day"
                                  value="Thursday"
                                  onChange={daysChangeHandler}
                                />
                                Thursday
                              </label>
                              <label className="d-flex align-items-center gap-2 me-2">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="day"
                                  value="Friday"
                                  onChange={daysChangeHandler}
                                />
                                Friday
                              </label>
                              <label className="d-flex align-items-center gap-2 ">
                                <input
                                  style={{ width: 20 }}
                                  type="checkbox"
                                  name="day"
                                  value="Saturday"
                                  onChange={daysChangeHandler}
                                />
                                Saturday
                              </label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="jobDescription">
                              Job description
                            </label>
                            <textarea
                              id="jobDescription"
                              name="jobDescription"
                              placeholder="Shift description"
                              className="form-control"
                              value={addJob.jobDescription}
                              onChange={changeHandlerCreateJob}
                              rows="3"
                              required
                            ></textarea>
                            {addJobErros.jobDescription && (
                              <div className="text-danger">
                                {addJobErros.jobDescription}
                              </div>
                            )}
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary"
                            id="btn-submit"
                            onClick={handleSubmitCreateJob}
                          >
                            Save All Changes
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EmployerDashboard;
