// messagesSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";

export const setMessage = createAsyncThunk("messages/setMessage", async (messageData) => {
  if (!messageData.id) {
    messageData.id = uuidv4();
  }
  const messagesRef = collection(db, "messages");

  return await setDoc(doc(messagesRef, messageData.id), messageData, { merge: true }).then(u => { 
    return {
      id: messageData.id
    };
  });
});

const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    message: undefined,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setMessage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default messagesSlice.reducer;
export const { actions: messagesActions } = messagesSlice;


