import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
  const [partnerLogo, setpartnerLogo] = useState(undefined);
  const location = useLocation();
  const { pathname } = location;
  const partner = useSelector((state) => state.partners.partner);
  const dispatch = useDispatch();
  useEffect(() => {
    const isPathName = pathname.startsWith("/partner");
    if (partner && isPathName) {
      setpartnerLogo({
        logoUrl: partner.logoUrl,
        logoName: partner.name,
      });
    }
  }, [partner, dispatch, pathname]);

  return (
    <footer className="footer mt-50">
      <div className="container">
        <div className="logo-row">
          <div className="footer-logo">
            <Link to="/">
              <img alt="Adult School Jobs" src="/logo.jpeg" />
            </Link>
          </div>

          {!partnerLogo ? (
            " "
          ) : (
            <div className="footer-logo">
              <img
                alt={partnerLogo.logoName}
                src={partnerLogo.logoUrl}
                className="partner-footer-logo"
              />
            </div>
          )}
        </div>
        <hr />

        <div className="row">
          <div className="footer-col-1 col-md-6 col-xs-4">
            <ul className="menu-footer">
              <li>
                <Link to="/legal/cookie-policy">Cookie Policy</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col-2 col-md-6 col-xs-4">
            <ul className="menu-footer">
              <li>
                <Link to="/legal/terms-of-service">Terms of Service</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col-3 col-md-6 col-xs-4">
            <ul className="menu-footer">
              <li>
                <Link to="/legal/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          
          <div className="footer-col-3 col-md-6 col-xs-4 ">
            <p>©assignments-hub 2023. All Rights Reserved.</p>
            {/* <div className="footer-social mt-2">
                            <Link className="icon-socials icon-facebook" to="#" />
                            <Link className="icon-socials icon-twitter" to="#" />
                            <Link className="icon-socials icon-linkedin" to="#" />
                        </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
