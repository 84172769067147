import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBu3voxBSAbvn6NBSpbCxFlL01byAsWWgA",
  authDomain: "coding-and-writings.firebaseapp.com",
  projectId: "coding-and-writings",
  storageBucket: "coding-and-writings.appspot.com",
  messagingSenderId: "841161759233",
  appId: "1:841161759233:web:639f162bdc2f147433fe00",
  measurementId: "G-N2BD7SKTPD"
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const authenticate = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);


