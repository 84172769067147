// userApplicationsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";

const setUserApplicationDoc = (userApplicationDoc) => {
  let userApplication = undefined;

  if (userApplicationDoc.exists()) {
    console.log('userApplicationDoc.exists()' , userApplicationDoc.exists())
      userApplication = {
        id: userApplicationDoc.data()?.id,
        email: userApplicationDoc.data()?.email,
        status: userApplicationDoc.data()?.status,
        job: userApplicationDoc.data()?.job,
        dateCreated: userApplicationDoc.data()?.dateCreated,
        user_id : userApplicationDoc.data()?.user_id
      
      };
  }

  return userApplication;
};

export const fetchUserApplications = createAsyncThunk(
  "userApplications/fetchUserApplications",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "user-applications"), where("id", "in", ids));
    } else {
      tempcollection = collection(db, "user-applications");
    }

    const querySnapshot = await getDocs(tempcollection);

    let userApplications = querySnapshot.docs.map((doc) => setUserApplicationDoc(doc));
    return userApplications;
  }
);
export const fetchUserApplicationsByUserId = createAsyncThunk(
  "userApplications/fetchUserApplicationsByUserId",
  async (userIds ) => {
   
    console.log('inside userIds', userIds)
     let tempcollection = query(collection(db, "user-applications"), where("user_id", "in", userIds));
    
    const querySnapshot = await getDocs(tempcollection);

    let userApplications = querySnapshot.docs.map((doc) => setUserApplicationDoc(doc));
    console.log('querySnapshot user app', querySnapshot)
    return userApplications;
  }
);

export const fetchUserApplication = createAsyncThunk("userApplications/fetchUserApplication", async (userApplicationId) => {
  const userApplicationDoc = await getDoc(
    doc(db, "user-applications", userApplicationId)
  );
  return setUserApplicationDoc(userApplicationDoc);
});

export const fetchUserApplicationsByEmail = createAsyncThunk(
  "userApplications/fetUserApplicationsByEmail", 
  async (email)=>{
    const q = query(
      collection(db, "user-applications"),
      where("email", "==", email)
    );
    const querySnapshot = await getDocs(q);

    let userApplicationsByEmail = querySnapshot.docs.map((doc)=>{
      return setUserApplicationDoc(doc);
      
    });
    return userApplicationsByEmail;
  }
)

export const setUserApplication = createAsyncThunk("userApplications/setUserApplication", async (userApplicationData) => {
  if (!userApplicationData.id) {
    userApplicationData.id = uuidv4();
  }
  const userApplicationsRef = collection(db, "user-applications");

  return await setDoc(doc(userApplicationsRef, userApplicationData.id), userApplicationData, {
    merge: true,
  }).then((u) => {
    return {
      id: userApplicationData.id,
      email: userApplicationData.email,
    };
  });
});

const initialState = {
  userApplication: undefined,
  userApplications: [],
  isLoading: false,
  error: null,
};
const userApplicationsSlice = createSlice({
  name: "userApplications",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserApplications.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApplications = action.payload;
      })
      .addCase(fetchUserApplications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserApplicationsByUserId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplicationsByUserId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApplications = action.payload;
      })
      .addCase(fetchUserApplicationsByUserId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserApplication.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApplication = action.payload;
      })
      .addCase(fetchUserApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserApplicationsByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplicationsByEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApplications = action.payload;
      })
      .addCase(fetchUserApplicationsByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setUserApplication.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setUserApplication.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setUserApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(userApplicationsActions.resetState, (state) => initialState);
  },
});

export default userApplicationsSlice.reducer;
export const { actions: userApplicationsActions } = userApplicationsSlice;
