import { Link } from "react-router-dom";
// import CategoryTab2 from "../components/elements/CategoryTab2";
// import BlogSlider from "../components/sliders/Blog";
// import StateSelect from "../components/elements/StateSelect";
// import {useSelector} from 

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../services/firebase";
import { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTaskFormData } from "../store/reducers/taskFormDataSlice";
import TestimonialSlider1 from "../components/sliders/Testimonial1";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { public_key_email, serviceId_email, task_templateId_email } from "../constants/email_config";
const Home = () => {
  // const [keyword, setKeyword] = useState("");
  // const [state, setState] = useState("CA");

  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    deadline: "",
    file_path: "",
    description : ""
  });
  const handleInputChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setSuccessMessage("");
  };
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formErrors, setErrors] = useState({});
  const [fileName, setFileName] = useState("");
  const taskFormData = useSelector((state) => state.taskFormData.taskFormData);
  const fileInputRef = useRef(null);
  const [fileData, setfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  function handlefileUpload(event) {
    setFileName(event.target.files[0]?.name || "");
    const file = event.target.files[0];
    setfileData(file);
  }

  useEffect(() => emailjs.init(public_key_email), []);
  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };
  function validateForm(formData) {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
      errors.name = "Name should only contain alphabets";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\+?\d+$/.test(formData.phone)) {
      errors.phone = "Invalid phone number format";
    }
    

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.deadline.trim()) {
      errors.message = "Deadline is required";
    }


    return errors;
  }
  // useEffect(()=>{

  // }, [formData.file_path])
  const handleSubmit_task = async (event) => {
    event.preventDefault();
   

    
    const errors = validateForm(formData);
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    if(formData?.deadline & formData.deadline.length > 0)
    {
      formData.deadline = new Date(formData.deadline) 

    }
    
    let file_path ;
    if(fileData)
    {
      const storageRef = ref(
        storage,
        "/task-files/" + fileData.name
      );
      await uploadBytes(storageRef, fileData);
      file_path = await getDownloadURL(storageRef);
      formData.file_path = file_path;
    }

  

    const serviceId = serviceId_email;
    const templateId = task_templateId_email;
    await dispatch(setTaskFormData(formData)).then(async () => {
      await emailjs.send(serviceId, templateId, {
        to_name: "Assignments Hub Admin",
        from_name: formData?.name,
        email: formData?.email, // Add individual fields like this
        phone: formData?.phone, // Add more fields if needed
        deadline: formData?.deadline,
        subject: formData?.subject,
        file_path: formData?.file_path,
        description : formData?.description
         
       });
       setSuccessMessage("Submitted successfully!, Our Representative will get back to you soon");
       // Reset the form and errors
       setFormData({
         name: "",
         email: "",
         phone: "",
         subject: "",
         deadline: "",
         file_path: "",
         description : "",
        });
        setFileName("")
        setLoading(false);
        alert("Submitted successfully!, Our Representative will get back to you soon ")
      setErrors({});
      setfileData(null);
      fileInputRef.current.value = '';
    });
  };

 
  //   setKeyword(event.target.value);
  // };

  // const handleStateChange = (event) => {
  //   setState(event.target.value);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   let query = "/jobs";
  //   let isFirstParam = true;
  //   if (keyword) {
  //     query += `?keyword=${encodeURIComponent(keyword)}`;
  //     isFirstParam = false;
  //   }

  //   if (state) {
  //     query = isFirstParam ? query + "?" : query + "&";
  //     query += `state=${encodeURIComponent(state)}`;
  //   }

  //   window.location.href = query;
  // };

  return (
    <>
      <div>
    
      <div className="d-xl-none">
  <div className="banner-imgs mx-auto" >
  <div className="block-1 shape-1">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/banner1.png" />
                        </div>
                        {/* <div className="block-2 shape-2">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/banner2.png" />
                        </div> */}
                        {/* <div className="block-3 shape-3">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/icon-top-banner.png" />
                        </div>
                        <div className="block-4 shape-3">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/icon-bottom-banner.png" />
                        </div> */}
  </div>
</div>
      <section className="section-box">
    <div className="banner-hero hero-1">
        <div className="banner-inner">
            <div className="row">
                <div className="col-xl-8 col-lg-12">
                    <div className="block-banner">
                        <h1 className="heading-banner wow animate__animated animate__fadeInUp">
                        From <span className="color-brand-2">Lines of Code </span>
                            <br className="d-none d-lg-block" />
                            to Lines of Prose
                        </h1>
                        <div className="banner-description mt-20 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                            Fill out following form to get Started <br className="d-none d-lg-block" />
                            <span class="relative flex h-3 w-3">
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
  <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
</span>
                        </div>
                        <div className="flex flex-row wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <form className="login-register text-start mt-20" 
                            id='task-data-form'
                            action="#"
                            method='post'>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-1">
                                        Full Name *
                                    </label>
                                    <input className="form-control" 
                                    name = 'name'
                                    id="input-1" 
                                    type="text" 
                                    // required name="fullname"
                                     placeholder="Steven Job" 
                                     value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                     />
                                     {formErrors.name && (
                                      <div className='text-danger'>{formErrors.name}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-2">
                                        Email *
                                    </label>
                                    <input className="form-control" 
                                    id="input-2"
                                     type="email" 
                                      name="email" 
                                     placeholder="stevenjob@gmail.com"
                                     value={formData.email}
                                    onChange={handleInputChange}
                                     required />
                                      {formErrors.email && (
                                      <div className='text-danger'>{formErrors.email}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-3">
                                        Subject
                                    </label>
                                    <input className="form-control" 
                                    id="input-3" 
                                    type="text" 
                                     name="subject"
                                     placeholder="Data Science"
                                     value={formData.subject}
                                    onChange={handleInputChange}
                                     required />

                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-4">
                                        Phone No. with Country Code *
                                    </label>
                                    <input className="form-control" 
                                    id="input-4"
                                     type="text" 
                                     name="phone"
                                      placeholder="+12124567890" 
                                      value={formData.phone}
                                    onChange={handleInputChange}
                                    required />
                                    {formErrors.phone && (
                                      <div className='text-danger'>{formErrors.phone}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-5">
                                        Deadline
                                    </label>
                                    <input className="form-control" 
                                    id="input-5"
                                     type="date" 
                                      name="deadline" 
                                     placeholder="mm/dd/yyyy"
                                     value={formData.deadline}
                                    onChange={handleInputChange}
                                    required />
                                    {formErrors.deadline && (
                                      <div className='text-danger'>{formErrors.deadline}</div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-5">
                                        File
                                    </label>
                                    <input  
                                    id="input-5" 
                                    type="file"
                                     name="file" 
                                     onChange={handlefileUpload}
                                     ref={fileInputRef} // Assign the ref to the file input
                                    //  value = {fileName}
                                     />
                                </div>
                                <div className="login_footer form-group d-flex justify-content-between">
                           
                                </div>
                                <label className="form-label" htmlFor="input-5">
                                        Description
                                    </label>
                                <div className="login_footer form-group d-flex justify-content-between">
                               
                                <textarea
                        className='font-sm color-text-paragraph-2'
                        name='description'
                        placeholder='Type your message here'
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                      />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-brand-1 hover-up w-100 bg-[#05264e]"
                                     type="submit" 
                                     name="submit" 
                                     disabled={loading} // Disable the button during loading
                                     onClick={handleSubmit_task}>
                                         {loading ? 'Submitting...' : 'Let\'s Start'} Let's Start
                                    </button>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-sm-12 d-none d-xl-block col-md-6">
                    <div className="banner-imgs">
                        <div className="block-1 shape-1">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/banner1.png" />
                        </div>
                        <div className="block-2 shape-2">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/banner2.png" />
                        </div>
                        <div className="block-3 shape-3">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/icon-top-banner.png" />
                        </div>
                        <div className="block-4 shape-3">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/icon-bottom-banner.png" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<div className="d-xl-none">
  <div className="banner-imgs mx-auto" >
  {/* <div className="block-1 shape-1">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/banner1.png" />
                        </div> */}
                        <div className="block-2 shape-2">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/banner2.png" />
                        </div>
                        {/* <div className="block-3 shape-3">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/icon-top-banner.png" />
                        </div>
                        <div className="block-4 shape-3">
                            <img className="img-responsive" alt="jobBox" src="assets/imgs/page/homepage1/icon-bottom-banner.png" />
                        </div> */}
  </div>
</div>


        <section className='section-box overflow-visible mt-50 mb-50'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                <div className='text-center'>
                  <h1 className='text-6xl leading-snug font-extrabold text-[#3C65F5]'>
                    <span className='count'>7</span>
                    <span>+</span>
                  </h1>
                  <h5 className='text-xl font-bold'>Years in Market</h5>
                  <p className='font-sm color-text-paragraph mt-10'>
                    Our team of experts are providing 24/7 services and live support for more than 7 years.
                  </p>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                <div className='text-center'>
                  <h1 className='text-6xl leading-snug font-extrabold text-[#3C65F5]'>
                    <span className='count'>&gt; 5000</span>
                    <span></span>
                  </h1>
                  <h5 className='text-xl font-bold'>Potential Customers</h5>
                  <p className='font-sm color-text-paragraph mt-10'>
                    There are thousands of individuals who have used our expert's services from around the globe.
                  </p>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                <div className='text-center'>
                  <h1 className='text-6xl leading-snug font-extrabold text-[#3C65F5]'>
                    <span className='count'>&gt; 90</span>
                    <span>%</span>
                  </h1>
                  <h5 className='text-xl font-bold'>Customer's Returning Rate</h5>
                  <p className='font-sm color-text-paragraph mt-10'>
                    More than 90% clients love to work for long term with us.
                  </p>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                <div className='text-center'>
                  <h1 className='text-6xl leading-snug font-extrabold text-[#3C65F5]'>
                    <span className='count'>&gt;10</span>
                  </h1>
                  <h5 className='text-xl font-bold'>Presence in Countries</h5>
                  <p className='font-sm color-text-paragraph mt-10'>
                    Our happy customers lives across the globe in more than 10 countries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className='section-box mt-50'>
          <div className='section-box wow animate__animated animate__fadeIn'>
            <div className='container'>
              <div className='text-start'>
                <h2 className='text-3xl font-bold'>Latest Jobs Post</h2>
                <p className='font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp'>
                  Explore the different types of available jobs to apply
                  <br className='d-none d-lg-block' />
                  discover which is right for you.
                </p>
              </div>
              <div className='mt-50'>
                <CategoryTab2 />
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className='section-box mt-50 mb-30 bg-border-3 pt-100 pb-100'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <img
                  className='bdrd-10'
                  src='assets/imgs/page/homepage5/img-profile.png'
                  alt='jobBox'
                />
              </div>
              <div className='col-lg-6'>
                <div className='pl-30'>
                  <h5 className='text-2xl font-bold text-[#S3C65F5] mb-15 mt-15'>
                    Create Profile
                  </h5>
                  <h2 className='text-4xl  font-bold mt-0 mb-15'>
                    Do you attend an Adult Learning or Job Center?
                  </h2>
                  <p className='font-lg color-text-paragraph-2'>
                    Create your profile here!
                  </p>
                  <div className='mt-20'>
                    <a className='btn btn-default' href='/register'>
                      Create Profile
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className='section-box mt-70 mb-40'>
          <div className='container'>
            <div className='text-start'>
              <h2 className='text-4xl font-bold'>How It Works</h2>
              <p className='font-lg mt-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp'>
                Learn how we engage our customers in a smooth process
              </p>
            </div>
            <div className='mt-70'>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='box-step step-1'>
                    <h1 className='number-element text-6xl pt-4 font-bold'>
                      1
                    </h1>
                    <h4 className='mb-20 text-2xl font-bold '>
                      Submit your
                      <br className='d-none d-lg-block' />
                      Detailed Query
                    </h4>
                    <p className='font-lg color-text-paragraph-2'>
                      Wait for Our Expert to 
                      <br className='d-none d-lg-block' />
                      Get Back to you
                    </p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='box-step step-2'>
                    <h1 className='number-element text-6xl pt-4 font-bold'>
                      2
                    </h1>
                    <h4 className='mb-20 text-2xl font-bold '>
                      Intract with
                      <br className='d-none d-lg-block' />
                      Our Expert
                    </h4>
                    <p className='font-lg color-text-paragraph-2'>
                      We sign up employers in the area
                      <br className='d-none d-lg-block' />
                      who are looking to hire workers.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='box-step'>
                    <h1 className='number-element text-6xl pt-4 font-boldt'>
                      3
                    </h1>
                    <h4 className='mb-20 text-2xl font-bold'>
                      Pay Initial 
                      <br className='d-none d-lg-block' />
                      Fee
                    </h4>
                    <p className='font-lg color-text-paragraph-2'>
                      We match workers with great companies. We help
                      institutions track outcomes through following up with both
                      employers and students to ensure everyone is on track.{" "}
                    </p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='box-step step-2'>
                    <h1 className='number-element text-6xl pt-4 font-bold'>
                      4
                    </h1>
                    <h4 className='mb-20 text-2xl font-bold '>
                      Get Quality
                      <br className='d-none d-lg-block' />
                      Work within Deadline
                    </h4>
                    <p className='font-lg color-text-paragraph-2'>
                      We sign up employers in the area
                      <br className='d-none d-lg-block' />
                      who are looking to hire workers.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='box-step step-2'>
                    <h1 className='number-element text-6xl pt-4 font-bold'>
                      5
                    </h1>
                    <h4 className='mb-20 text-2xl font-bold '>
                    Clear all
                      <br className='d-none d-lg-block' />
                      Dues and Enjoy!
                    </h4>
                    <p className='font-lg color-text-paragraph-2'>
                      We sign up employers in the area
                      <br className='d-none d-lg-block' />
                      who are looking to hire workers.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='box-step'>
                    <h1 className='number-element text-6xl pt-4 font-boldt'>
                      6
                    </h1>
                    <h4 className='mb-20 text-2xl font-bold'>
                     Come Again With
                      <br className='d-none d-lg-block' />
                      Another Task
                    </h4>
                    <p className='font-lg color-text-paragraph-2'>
                      Come back to us with any other task and repeat the process{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-50 text-center'>
              <a className='btn btn-default' href='/'>
                Get Started
              </a>
            </div>
          </div>
        </section>

        <section className='section-box mt-70'>
          <div className='container'>
            <div className='text-start'>
              <h2 className='text-3xl font-extrabold'>
                Featured Clients who have been working for long term with us
              </h2>
              {/* <p className='font-lg pt-2 color-text-paragraph-2 wow animate__animated animate__fadeInUp'>
                Every student site will have a revolving list of top candidates
                to feature for schools.
              </p> */}
            </div>

            <div className="box-swiper">
                                    <TestimonialSlider1 />
                                </div>
          </div>
        </section>

        {/* <section className='section-box mt-50 mb-50'>
          <div className='container'>
            <div className='text-start'>
              <h2 className='section-title mb-10 text-4xl font-bold wow animate__animated animate__fadeInUp'>
                News and Blog
              </h2>
              <p className='font-lg pt-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp'>
                Get the latest news, updates and tips - we post new content from
                ourselves and our partners on a regular basis.
              </p>
            </div>
          </div>
          <div className='container'>
            <div className='mt-50'>
            <section className="section-box mt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="card-grid-5">
                                        <div className="card-grid-5 hover-up" style={{ backgroundImage: 'url("assets/imgs/page/blog/img-big1.png")' }}>
                                            <Link legacyBehavior href="blog-details">
                                                <a>
                                                    <div className="box-cover-img">
                                                        <div className="content-bottom">
                                                            <h3 className="color-white mb-20">11 Tips to Help You Get New Clients</h3>
                                                            <div className="author d-flex align-items-center mr-20">
                                                                <img className="mr-10" alt="jobBox" src="assets/imgs/page/candidates/user3.png" />
                                                                <span className="color-white font-sm mr-25">Azumi Rose</span>
                                                                <span className="color-white font-sm">25 April 2022</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="card-grid-5 hover-up" style={{ backgroundImage: 'url("assets/imgs/page/blog/img-big2.png")' }}>
                                        <Link legacyBehavior href="blog-details">
                                            <a>
                                                <div className="box-cover-img">
                                                    <div className="content-bottom">
                                                        <h3 className="color-white mb-20">Recruiter and Land Your Dream Job</h3>
                                                        <div className="author d-flex align-items-center mr-20">
                                                            <img className="mr-10" alt="jobBox" src="assets/imgs/page/candidates/user1.png" />
                                                            <span className="color-white font-sm mr-25">Thompson</span>
                                                            <span className="color-white font-sm">28 April 2022</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="card-grid-5 hover-up" style={{ backgroundImage: 'url("assets/imgs/page/blog/img-big3.png")' }}>
                                        <Link legacyBehavior href="blog-details">
                                            <a>
                                                <div className="box-cover-img">
                                                    <div className="content-bottom">
                                                        <h3 className="color-white mb-20">Work-From-Home Jobs That Pay Well</h3>
                                                        <div className="author d-flex align-items-center mr-20">
                                                            <img className="mr-10" alt="jobBox" src="assets/imgs/page/blog/ava_1.png" />
                                                            <span className="color-white font-sm mr-25">Alice Json</span>
                                                            <span className="color-white font-sm">29 April 2022</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
              <div className='box-swiper style-nav-top '>
                <BlogSlider />
              </div>
              <div className="text-center">
                                    <Link className="btn btn-brand-1 btn-icon-load mt--30 hover-up" to="/blog-grid">
                                        Load More Posts
                                    </Link>
                                </div>
            </div>
          </div>
        </section> */}

        <section className='section-box mt-50 mb-20'>
          <div className='container'>
            <div className='box-newsletter box-newsletter-3'>
              <div className='row'>
                <div className='col-xl-12 text-center'>
                  <div className='d-inline-block'>
                    <h2 className='color-white text-4xl font-bold mt-30'>
                      Join now to get more information or register.
                    </h2>
                    <div className='box-form-newsletter mt-30'>
                      <form className='form-newsletter'>
                        <input
                          className='input-newsletter'
                          type='text'
                          placeholder='Enter your email here'
                        />
                        <button className='btn btn-default font-heading icon-send-letter'>
                          Join
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
