// employersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";

const setEmployerDoc = (employerDoc) => {
  let employer = undefined;

  if (employerDoc.exists()) {
    employer = {
      id: employerDoc.data().id,
      email: employerDoc.data().email,
      name: employerDoc.data().name,
      photoUrl: employerDoc.data().photoUrl,
      partnerId: employerDoc.data().partnerId,
      skill: employerDoc.data().skill,
      bio: employerDoc.data().bio,
      contactNumber: employerDoc.data().contactNumber,
      state: employerDoc.data().state,
      city: employerDoc.data().city,
      zipCode: employerDoc.data().zipCode,
      addressLine1: employerDoc.data().addressLine1,
      addressLine2: employerDoc.data().addressLine2,
    };
  }

  return employer;
}

export const fetchEmployers = createAsyncThunk("employers/fetchEmployers", async () => {
  const querySnapshot = await getDocs(collection(db, "employers"));
  let employers = querySnapshot.docs.map((doc) => setEmployerDoc(doc));
  return employers;
});

export const fetchEmployer = createAsyncThunk("employers/fetchEmployer", async (employerId) => {
  const employerDoc = await getDoc(doc(db, "employers", employerId));
  return setEmployerDoc(employerDoc);
});

export const fetchEmployerByEmail = createAsyncThunk(
  "employers/fetchEmployerByEmail",
  async (email) => {
    const q = query(collection(db, "employers"), where("email", "==", email));

    const querySnapshot = await getDocs(q);
    let employers = querySnapshot.docs.map((doc) => setEmployerDoc(doc));
    return employers[0];
  }
);

export const setEmployer = createAsyncThunk("employers/setEmployer", async (employerData) => {
  if (!employerData.id) {
    employerData.id = uuidv4();
  }
  const employersRef = collection(db, "employers");

  return await setDoc(doc(employersRef, employerData.id), employerData, { merge: true }).then(u => { 
    return {
      id: employerData.id,
      email: employerData.email,
    };
  });
});

const employersSlice = createSlice({
  name: "employers",
  initialState: {
    employer: undefined,
    employers: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employers = action.payload;
      })
      .addCase(fetchEmployers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchEmployer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employer = action.payload;
      })
      .addCase(fetchEmployer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchEmployerByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployerByEmail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchEmployerByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setEmployer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setEmployer.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setEmployer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default employersSlice.reducer;
export const { actions: employersActions } = employersSlice;


