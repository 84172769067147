// jobsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  getDoc,
  addDoc,
  query,
  where,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";


const setJobDoc = (jobRef) => {
  let job = undefined;

  if (jobRef.exists()) {
    job = {
      id: jobRef.data().id,
      employerId: jobRef.data().employeeId,
      addressLine1: jobRef.data().addressLine1,
      addressLine2: jobRef.data().addressLine2,
      businessName: jobRef.data().businessName,
      city: jobRef.data().city,
      contactName: jobRef.data().contactName,
      contactNumber: jobRef.data().contactNumber,

      title: jobRef.data().title,
      days: jobRef.data().days,

      longDescription: jobRef.data().longDescription,
      shortDescription: jobRef.data().shortDescription,
      hours: jobRef.data().hours,
      hoursDescription: jobRef.data().hoursDescription,
      photoUrl: jobRef.data().photoUrl,
      isActive: jobRef.data().isActive,
      language: jobRef.data().language,
      sortOrder: jobRef.data().sortOrder,
      owner: jobRef.data().owner,
      pay: jobRef.data().pay,
      shiftDescription: jobRef.data().shiftDescription,
      state: jobRef.data().state,
      type: jobRef.data().type,
      zipCode: jobRef.data().zipCode,
      country: jobRef.data().country,
      program: jobRef.data().program,
    };
  }
  if (!job.contactNumber && jobRef.data().contact) {
    job.contactNumber = jobRef.data().contact;
  }
  
  return job;
};
export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async () => {
    const querySnapshot = await getDocs(collection(db, "jobs"));
    let job = querySnapshot.docs.map((doc) => setJobDoc(doc));
    return job;
  }
);

export const setJob = createAsyncThunk("jobs/setJob", async (jobData) => {

  if (!jobData.id) {
    jobData.id = uuidv4();
    jobData.dateCreated = new Date();
    jobData.datePosted = new Date();
  }
  jobData.dateUpdated = new Date();
  const jobsRef = collection(db, "jobs");

  return await setDoc(doc(jobsRef, jobData.id), jobData, {
    merge: true,
  }).then(() => {
    return {
      id: jobData.id,
    };
  });
});

export const fetchJob = createAsyncThunk("jobs/fetchJob", async (jobId) => {
  const jobDoc = await getDoc(doc(db, "jobs", jobId));
  console.log("jobDoc", jobDoc);
  return setJobDoc(jobDoc);
});

export const createJob = createAsyncThunk(
  "Jobs/createjob",
  async ({ job, setActiveIndex }, thunkAPI) => {
    console.log(job, "datadatadata");
    try {
      const docRef = await addDoc(collection(db, "Jobs2"), job);
      console.log(docRef.id, job.employer_email, job, "docRefdocRefdocRef");

      if (docRef.id !== undefined && docRef.id !== null) {
        setActiveIndex(2);
      }
      // if(docRef)
      return docRef;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getJobsByEmail = createAsyncThunk(
  "users/fetchUserByEmail",
  async (email, thunk) => {
    const q = query(collection(db, "Jobs2"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    let newData = [];
    querySnapshot.forEach((data, index) => {
      newData.push(data.data());
    });
    return thunk.fulfillWithValue(newData);
  }
);
const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    createJobResponse: null,
    job: undefined,
    allJobs: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setJob.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setJob.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchJob.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.job = action.payload;
      })
      .addCase(fetchJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createJob.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createJobResponse = action.payload;
        state.error = null;
      })
      .addCase(createJob.rejected, (state, action) => {
        state.isLoading = false;
        state.createJobResponse = null;
        state.error = action.error.message;
      })
      .addCase(getJobsByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getJobsByEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allJobs = action.payload;
        state.error = "";
      })
      .addCase(getJobsByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.allJobs = [];
        state.error = action.error.message;
      });
  },
});

export default jobsSlice.reducer;
export const { actions: jobsActions } = jobsSlice;
