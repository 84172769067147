
// import Link from "next/link";
// import Layout from "../components/Layout/Layout";
import BlogSlider from "./../components/sliders/Blog";
import TestimonialSlider1 from "./../components/sliders/Testimonial1";

export default function About() {
    return (
        <>
            {/* <Layout> */}
                <div>
                    <section className="section-box">
                        <div className="breacrumb-cover bg-img-about">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2 className="mb-10">About Us</h2>
                                        <p className="font-lg color-text-paragraph-2">Get the latest news, updates and tips</p>
                                    </div>
                                    <div className="col-lg-6 text-lg-end">
                                        <ul className="breadcrumbs mt-40">
                                            <li>
                                                <a className="home-icon" href="#">
                                                    Home
                                                </a>
                                            </li>
                                            <li>About Us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-box mt-50">
                        <div className="post-loop-grid">
                            <div className="container">
                                <div className="text-center">
                                    <h6 className="f-18 color-text-mutted text-uppercase text-4xl font-bold">Our company</h6>
                                    <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">About Our Company</h2>
                                    <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">Welcome to Assignments Hub – your one-stop destination for top-quality Assignments Hub services. Our platform is designed to cater to the diverse needs of both students and professionals, offering a comprehensive range of services in the realms of Assignments Hub.</p>
                                </div>
                                <div className="row mt-70">
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <img src="assets/imgs/page/about/img-about2.png" alt="joxBox" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <h3 className="mt-15 text-2xl font-bold">Who we are?</h3>
                                        <div className="mt-20">
                                            <p className="font-md color-text-paragraph mt-20">We are a team of dedicated and skilled experts who are passionate about delivering excellence in both Assignments Hub domains. With a strong commitment to providing exceptional services, we have brought together a group of seasoned coders and experienced writers to meet your unique requirements.</p>
                                            {/* <h3 className="mt-15">Our Services</h3>
                                            <h3 className="mt-15">Coding Services</h3>
                                            <p className="font-md color-text-paragraph mt-20">Whether you're a student grappling with coding assignments or a professional seeking assistance with a coding project, our coding services are tailored to meet your needs. From programming assignments to complex projects, our coding experts are proficient in various programming languages and technologies. We provide guidance, code review, debugging, and more to ensure your coding tasks are executed flawlessly.</p>
                                            <h3 className="mt-15">Writing Services</h3>
                                            <p className="font-md color-text-paragraph mt-20">Our writing services encompass a wide spectrum of needs. From academic writing such as thesis and research paper composition, to business writing like reports and proposals, we have a team of skilled writers who excel in delivering well-crafted content. Whether you need to communicate complex technical concepts or persuasive business arguments, our writing services are designed to articulate your ideas effectively.</p> */}
                                            {/* <p className="font-md color-text-paragraph mt-20">Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis non nisi purus. Integer sit nostra, per inceptos himenaeos.</p> */}
                                        </div>
                                        {/* <div className="mt-30">
                                            <a className="btn btn-brand-1" href="#">
                                                Read More
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-box mt-80">
                        <div className="post-loop-grid">
                            <div className="container">
                                {/* <div className="text-center">
                                    <h6 className="f-18 color-text-mutted text-uppercase">Our Services</h6>
                                    <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Coding Domain</h2>
                                    <p className="font-sm color-text-paragraph w-lg-50 mx-auto wow animate__animated animate__fadeInUp">Whether you're a student grappling with coding assignments or a professional seeking assistance with a coding project, our coding services are tailored to meet your needs. From programming assignments to complex projects, our coding experts are proficient in various programming languages and technologies. We provide guidance, code review, debugging, and more to ensure your coding tasks are executed flawlessly.</p>
                                </div> */}
                                <div className="row mt-70">
                                    {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team1.png" />
                                                </figure>
                                            </div>

                                        </div>

                                    </div> */}

<h2 className='text-4xl font-bold'>Our Services</h2>
                                    <div className="card-grid-4-info">
                                        <br></br>
                                    <h2 className='text-1xl font-bold'>Coding Services</h2>
    {/* <h1 className="mt-10">Coding Service</h1> */}
    {/* <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Frontend Developer</p> */}
    {/* <div className="rate-reviews-small pt-5">
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span className="ml-10 color-text-mutted font-xs">
            <span>(</span>
            <span>65</span>
            <span>)</span>
        </span>
    </div> */}
    <p className="mt-15 mb-15">
        Unlock the potential of code with our comprehensive coding services. Whether you're a student facing a challenging assignment or a professional seeking to enhance your programming skills, our team of experienced coders is here to assist you. From debugging and algorithm optimization to project development and code review, we offer a wide range of coding solutions tailored to your needs. Let us help you turn your coding challenges into success stories.
    </p>
    <p className="font-xs color-text-paragraph-2 mt-5 mb-5">
        Key Features:
    </p>
    <ul className="mb-15">
        <li>Assignment Assistance: Get step-by-step guidance and solutions for coding assignments.</li>
        <li>Code Review: Receive constructive feedback to improve the quality of your code.</li>
        <li>Algorithm Optimization: Enhance the efficiency and performance of your algorithms.</li>
        <li>Project Development: Collaborate with us to bring your software projects to life.</li>
        <li>Custom Solutions: Tailored coding solutions to address your unique requirements.</li>
    </ul>
    <p>
        Don't let coding hurdles hold you back. Explore our coding services today and experience coding excellence like never before!
    </p>
</div>
                                    <div className="card-grid-4-info">
                                    <h2 className='text-1xl font-bold'>Writing Services</h2>
    {/* <h1 className="mt-10">Writing Services</h1> */}
    {/* <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Content Writer</p> */}
    {/* <div className="rate-reviews-small pt-5">
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span>
            <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
        </span>
        <span className="ml-10 color-text-mutted font-xs">
            <span>(</span>
            <span>72</span>
            <span>)</span>
        </span>
    </div> */}
    <p className="mt-15 mb-15">
        Unleash your creativity and communicate effectively with our top-tier writing services. Whether you're a student working on an academic paper, a professional crafting reports, or an individual seeking impactful content, our skilled writers are here to empower your words. We offer a diverse range of writing solutions that cater to various industries and niches. Let us transform your ideas into compelling narratives that resonate.
    </p>
    <p className="font-xs color-text-paragraph-2 mt-5 mb-5">
        Key Features:
    </p>
    <ul className="mb-15">
        <li>Academic Writing: Get expert assistance with essays, research papers, theses, and more.</li>
        <li>Business Reports: Deliver polished business reports that impress and inform.</li>
        <li>Creative Content: Elevate your brand with engaging blog posts, articles, and website content.</li>
        <li>Editing and Proofreading: Ensure your writing is error-free and polished to perfection.</li>
        <li>Customized Approach: Receive personalized writing solutions tailored to your objectives.</li>
    </ul>
    <p>
        Experience the power of words with our writing services. Collaborate with our dedicated writers and elevate your written communication to new heights.
    </p>
</div>

                                    {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team2.png" />
                                                </figure>
                                            </div>
                                            <div className="card-grid-4-info">
                                                <h5 className="mt-10">Floyd Miles</h5>
                                                <p className="font-xs color-text-paragraph-2 mt-5 mb-5">UI/UX Designer</p>
                                                <div className="rate-reviews-small pt-5">
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span className="ml-10 color-text-mutted font-xs">
                                                        <span>(</span>
                                                        <span>28</span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                                <span className="card-location">Chicago, US</span>
                                                <div className="text-center mt-30">
                                                    <a className="share-facebook social-share-link" href="#" />
                                                    <a className="share-twitter social-share-link" href="#" />
                                                    <a className="share-instagram social-share-link" href="#" />
                                                    <a className="share-linkedin social-share-link" href="#" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team3.png" />
                                                </figure>
                                            </div>
                                            <div className="card-grid-4-info">
                                                <h5 className="mt-10">Devon Lane</h5>
                                                <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Frontend Developer</p>
                                                <div className="rate-reviews-small pt-5">
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span className="ml-10 color-text-mutted font-xs">
                                                        <span>(</span>
                                                        <span>65</span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                                <span className="card-location">New York, US</span>
                                                <div className="text-center mt-30">
                                                    <a className="share-facebook social-share-link" href="#" />
                                                    <a className="share-twitter social-share-link" href="#" />
                                                    <a className="share-instagram social-share-link" href="#" />
                                                    <a className="share-linkedin social-share-link" href="#" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team4.png" />
                                                </figure>
                                            </div>
                                            <div className="card-grid-4-info">
                                                <h5 className="mt-10">Jerome Bell</h5>
                                                <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Frontend Developer</p>
                                                <div className="rate-reviews-small pt-5">
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span className="ml-10 color-text-mutted font-xs">
                                                        <span>(</span>
                                                        <span>65</span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                                <span className="card-location">New York, US</span>
                                                <div className="text-center mt-30">
                                                    <a className="share-facebook social-share-link" href="#" />
                                                    <a className="share-twitter social-share-link" href="#" />
                                                    <a className="share-instagram social-share-link" href="#" />
                                                    <a className="share-linkedin social-share-link" href="#" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team5.png" />
                                                </figure>
                                            </div>
                                            <div className="card-grid-4-info">
                                                <h5 className="mt-10">Theresa</h5>
                                                <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Frontend Developer</p>
                                                <div className="rate-reviews-small pt-5">
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span className="ml-10 color-text-mutted font-xs">
                                                        <span>(</span>
                                                        <span>65</span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                                <span className="card-location">New York, US</span>
                                                <div className="text-center mt-30">
                                                    <a className="share-facebook social-share-link" href="#" />
                                                    <a className="share-twitter social-share-link" href="#" />
                                                    <a className="share-instagram social-share-link" href="#" />
                                                    <a className="share-linkedin social-share-link" href="#" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team6.png" />
                                                </figure>
                                            </div>
                                            <div className="card-grid-4-info">
                                                <h5 className="mt-10">Cameron</h5>
                                                <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Frontend Developer</p>
                                                <div className="rate-reviews-small pt-5">
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span className="ml-10 color-text-mutted font-xs">
                                                        <span>(</span>
                                                        <span>65</span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                                <span className="card-location">New York, US</span>
                                                <div className="text-center mt-30">
                                                    <a className="share-facebook social-share-link" href="#" />
                                                    <a className="share-twitter social-share-link" href="#" />
                                                    <a className="share-instagram social-share-link" href="#" />
                                                    <a className="share-linkedin social-share-link" href="#" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team7.png" />
                                                </figure>
                                            </div>
                                            <div className="card-grid-4-info">
                                                <h5 className="mt-10">Jacob Jones</h5>
                                                <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Frontend Developer</p>
                                                <div className="rate-reviews-small pt-5">
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span className="ml-10 color-text-mutted font-xs">
                                                        <span>(</span>
                                                        <span>65</span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                                <span className="card-location">New York, US</span>
                                                <div className="text-center mt-30">
                                                    <a className="share-facebook social-share-link" href="#" />
                                                    <a className="share-twitter social-share-link" href="#" />
                                                    <a className="share-instagram social-share-link" href="#" />
                                                    <a className="share-linkedin social-share-link" href="#" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-md-30">
                                        <div className="card-grid-4 text-center hover-up">
                                            <div className="image-top-feature">
                                                <figure>
                                                    <img alt="jobBox" src="assets/imgs/page/about/team8.png" />
                                                </figure>
                                            </div>
                                            <div className="card-grid-4-info">
                                                <h5 className="mt-10">Court Henry</h5>
                                                <p className="font-xs color-text-paragraph-2 mt-5 mb-5">Frontend Developer</p>
                                                <div className="rate-reviews-small pt-5">
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span>
                                                        <img src="assets/imgs/template/icons/star.svg" alt="jobBox" />
                                                    </span>
                                                    <span className="ml-10 color-text-mutted font-xs">
                                                        <span>(</span>
                                                        <span>65</span>
                                                        <span>)</span>
                                                    </span>
                                                </div>
                                                <span className="card-location">New York, US</span>
                                                <div className="text-center mt-30">
                                                    <a className="share-facebook social-share-link" href="#" />
                                                    <a className="share-twitter social-share-link" href="#" />
                                                    <a className="share-instagram social-share-link" href="#" />
                                                    <a className="share-linkedin social-share-link" href="#" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="section-box mt-50 mb-50">
                        <div className="container">
                            <div className="text-start">
                                <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">News and Blog</h2>
                                <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">Get the latest news, updates and tips</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="mt-50">
                                <div className="box-swiper style-nav-top">
                                    <BlogSlider />
                                </div>
                                <div className="text-center">
                                    <Link legacyBehavior href="/blog-grid">
                                        <a className="btn btn-brand-1 btn-icon-load mt--30 hover-up">Load More Posts</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section className="section-box mt-30 mb-40">
                        <div className="container">
                        {/* <h2 className='text-1xl font-bold'>Writing Services</h2> */}
                            <h2 className="text-center mb-15 wow animate__animated animate__fadeInUp text-4xl font-bold">Our Happy Customers</h2>
                            <div className="font-lg color-text-paragraph-2 text-center wow animate__animated animate__fadeInUp">
                                {/* When it comes to choosing the right web hosting provider, we know how easy it
                                <br className="d-none d-lg-block" /> is to get overwhelmed with the number. */}
                            </div>
                            <div className="row mt-50">
                                <div className="box-swiper">
                                    <TestimonialSlider1 />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-box mt-50 mb-20">
                        <div className="container">
                            <div className="box-newsletter">
                                <div className="row">
                                    <div className="col-xl-3 col-12 text-center d-none d-xl-block">
                                        <img src="assets/imgs/template/newsletter-left.png" alt="joxBox" />
                                    </div>
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <h2 className="text-md-newsletter text-center">
                                            New Things Will Always
                                            <br /> Update Regularly
                                        </h2>
                                        <div className="box-form-newsletter mt-40">
                                            <form className="form-newsletter">
                                                <input className="input-newsletter" type="text" placeholder="Enter your email here" />
                                                <button className="btn btn-default font-heading icon-send-letter">Subscribe</button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-12 text-center d-none d-xl-block">
                                        <img src="assets/imgs/template/newsletter-right.png" alt="joxBox" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            {/* </Layout> */}
        </>
    );
}
