import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions, signOut } from "../../store/reducers/authSlice";
import { partnersActions } from "../../store/reducers/partnerSlice";
import { usersActions } from "../../store/reducers/usersSlice";

const Header = ({ handleOpen, handleRemove, openClass }) => {
  const [showStickyBar, setScroll] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const partner = useSelector((state) => state.partners.partner);
  const { pathname } = location;
  const [logo, setLogo] = useState(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const shouldShowStickyBar = window.scrollY > 100;
      if (shouldShowStickyBar !== showStickyBar) {
        setScroll(shouldShowStickyBar);
      }
    });
  });

  useEffect(() => {
    const isPartnerPage = pathname.startsWith("/partner");
    if (partner && isPartnerPage) {
      setLogo({ logoUrl: partner.logoUrl, partnerName: partner.name });
    }
  }, [partner, dispatch, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSignOut = () => {
    dispatch(signOut()).then(() => {
      dispatch(authActions.resetState());
      dispatch(partnersActions.resetState());
      dispatch(usersActions.resetState());
      setLogo(undefined);
      setIsLoggedIn(undefined);
      localStorage.setItem("isLoggedIn",false)
      localStorage.removeItem("userId")
      navigate("/");
    });
  };

  useEffect(() => {
    const storedData = localStorage.getItem("auth");
    if (storedData !== undefined) {
      setIsLoggedIn(JSON.parse(storedData));
    }
  }, [auth?.auth]);

  return (
    <>
      <header
        className={
          showStickyBar ? "header sticky-bar stick" : "header sticky-bar"
        }
      >
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                
                  <Link to="/">
                    <img alt="Assignments Hub" src="/logo.jpeg" />
                  </Link>
                
              </div>
            </div>
            <div className="header-nav">
              <nav className="nav-main-menu">
                <ul className="main-menu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li className="has-children active">
                    <Link>Find a Job</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/jobs-grid">Jobs Grid</Link>
                      </li>
                      <li>
                        <Link to="/jobs">Jobs List</Link>
                      </li>
                      <li>
                        <Link to="/job-details">Jobs Details</Link>
                      </li>
                      <li>
                        <Link to="/job-details-2">Jobs Details 2</Link>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  {/* <li>
                    <Link to="/your-jobs">Your Job</Link>
                  </li> */}
                  {/* <li className="has-children">
                    <Link>Employers</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/employer/auth">Sign in</Link>
                      </li>
                      <li>
                        <Link to="/employer/dashboard">Dashboard</Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li>
                      <Link to="/blogs">Blogs</Link>
                  </li> */}

                  {/* <li>
                    {isLoggedIn ? 
                    <Link to="/partner/dashboard">Partners</Link>:
                    <Link to="/partner/register">Partners</Link>
                    }
                  </li> */}
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  {/* <li className="has-children">
                    <Link>Partner Admin</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/partner/auth">Sign in</Link>
                      </li>
                      <li>
                        <Link to="/partner/dashboard">Dashboard</Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </nav>
              <div
                className={`burger-icon burger-icon-white ${
                  openClass && "burger-close"
                }`}
                onClick={() => {
                  handleOpen();
                  handleRemove();
                }}
              >
                <span className="burger-icon-top" />
                <span className="burger-icon-mid" />
                <span className="burger-icon-bottom" />
              </div>
            </div>
            {/* <div className="header-right">
              {isLoggedIn?.email ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handleSignOut}
                >
                  Sign Out
                </button>
              ) : (
                <div className="block-signin">
                  <Link to="register" className="text-link-bd-btom hover-up">
                    Register
                  </Link>
                  <Link
                    to="auth"
                    className="btn btn-default btn-shadow ml-40 hover-up"
                  >
                    Sign in
                  </Link>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
