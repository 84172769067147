import { combineReducers } from "redux";
import jobsReducer from "./jobsSlice";
import usersReducer from "./usersSlice";
import AuthReducer from "./authSlice";
import partnerReducer from "./partnerSlice";
import employerReducer from "./employersSlice";
import userApplicationsReducer from "./userApplicationsSlice";
import messagesReducer from "./messagesSlice";
import contactFormReducer from "./contactSlice";
import partnerStudentReducer from "./partnerStudentSlice"
import UsersChatReducer from "./usersChatsSlice"
import ChatMessagesReducer from "./chatMessagesSlice"
import taskFormDataReducer from "./taskFormDataSlice";
const rootReducer = combineReducers({
  jobs: jobsReducer,
  users: usersReducer,
  auth: AuthReducer,
  partners: partnerReducer,
  employers: employerReducer,
  userApplications: userApplicationsReducer,
  messages: messagesReducer,
  contactForm: contactFormReducer,
  partnerStudents : partnerStudentReducer,
  usersChats : UsersChatReducer,
  chatMessages :ChatMessagesReducer,
  taskFormData : taskFormDataReducer
});


export default rootReducer;
