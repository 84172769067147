import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query,  where, getDocs, or, setDoc, doc } from "firebase/firestore";
import { db } from "../../services/firebase";
// import { v4 as uuidv4 } from "uuid";
// import { useDispatch, useSelector } from "react-redux";
import { get_combinedusersChatsId } from "../../components/utills/messagedata";
// Set up Firebase collection for chats
// const chatsCollection = collection(db, "usersChats");
let unsubscribeChatListener; // Variable to hold the unsubscribe function

// Helper function to parse chat data
const setChatDoc = (chatDoc) => {
  console.log('chat set' ,chatDoc.data() )
  let chat = undefined;
  if (chatDoc.exists()) {
    chat = {
      id: chatDoc.id,
      message: chatDoc.data().message,
      receiverId: chatDoc.data().receiverId,
      senderId: chatDoc.data().senderId,
      timestamp: chatDoc.data().timestamp,
      photoUrl : chatDoc.data().userinfo?.photoUrl,
      displayName : chatDoc.data().userinfo?.displayName,
      lastMessage : chatDoc.data().userinfo?.lastMessage,
    };
  }
  console.log('chattt' , chat)
  return chat;
};

// Async thunk to send a chat message
export const addUserChatsData = createAsyncThunk(
  "usersChats/addUserChatsData",
  async ({  receiverId, senderId , displayName, photoUrl , lastMessage , chatId }) => {
    
    console.log('add function call')
    if(!chatId)
    {
       chatId = get_combinedusersChatsId(senderId, receiverId)

    }
    const chatData = {
      
      receiverId :receiverId,
      senderId :senderId ,
      timestamp: new Date(),
      userinfo : {
        displayName : displayName, 
        photoUrl :photoUrl , 
        lastMessage :lastMessage
      }
    };

    console.log('hereee in user chat data')
     // const chatDocRef = await addDoc(collection(db, "usersChats" , chat_id), chatData);
    const chatDocRef = await setDoc(doc(db, "usersChats" , chatId ), chatData , {
      merge: true,
    }).then((u) => {
      return {
        id: chatDocRef.id,
        ...chatData,
      }
    });

  }
);

// Async thunk to listen for incoming chat messages
export const listenForChatMessages = createAsyncThunk(
  "usersChats/listenForChatMessages",
  async (userId) => {
    
    // const querySnapshot = await getDocs(query(collection(db, "usersChats"), where("receiverId", "==", userId.trim(), or , "senderId", "==", userId.trim())));
    const querySnapshot = await getDocs(query(collection(db, "usersChats"), or(
      where("receiverId", "==", userId.trim()), 
    where( "senderId", "==", userId.trim())
    ),
    // orderBy("timestamp")
    )
    );
    // const querySnapshot = await getDocs(query(collection(db, "usersChats"), where("id", "in", userId.trim())));

    let users = querySnapshot.docs.map((doc) => setChatDoc(doc));
    return users;

  }
);


export const unsubscribeChat = () => {
  if (unsubscribeChatListener) {
    unsubscribeChatListener();
  }
};



// Action creator to update chatMessages state
export const updateChatMessages = (chatMessages) => ({
  type: "usersChats/updateChatMessages",
  payload: chatMessages,
});

const initialState = {
  chatMessages: [],
  chat : undefined,
  isLoading: false,
  error: null,
};

const usersChatsSlice = createSlice({
  name: "usersChats",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserChatsData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addUserChatsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatMessages.push(action.payload);
      })
      .addCase(addUserChatsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(listenForChatMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listenForChatMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatMessages = action.payload;
      })
      .addCase(listenForChatMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(usersChatsActions.resetState, (state) => initialState);
  },
});

export default usersChatsSlice.reducer;
export const { actions: usersChatsActions } = usersChatsSlice;
