import { fetchUserByEmail, setUser } from "../store/reducers/usersSlice";

export const setUpUserAndNavigate = (dispatch, authUser, navigate) => {
  console.log("setUpUserAndNavigate", authUser.payload.email);
  dispatch(fetchUserByEmail(authUser.payload.email)).then((user) => {
    console.log("setUpUserAndNavigate - user", user);
    if (user.payload) {
      if (user.payload.isPartnerAdmin) {
        localStorage.setItem("userId", user.payload.id); // Store the user ID in localStorage
        navigate(`/partner/dashboard?id=${user.payload.id}`);
      } else if (user.payload.isEmployer) {
        console.log('user', user.payload);
        localStorage.setItem("userId", user.payload.id); // Store the user ID in localStorage
        navigate(`/employer/dashboard?id=${user.payload.id}`);
      } else if (user.payload.isParnter) {
        localStorage.setItem("partnerId", user.payload.partnerId); // Store the partner ID in localStorage
        localStorage.setItem("userId", user.payload.id); // Store the user ID in localStorage
        navigate(
          `/partner/jobs?partnerId=${user.payload.partnerId}&userId=${user.payload.id}`
        );
      } else {
        localStorage.setItem("userId", user.payload.id); // Store the user ID in localStorage
        navigate("/profile");
      }
    } else {
      dispatch(
        setUser({
          email: authUser.payload.email,
          dateCreated: new Date(),
        })
      ).then((newUser) => {
        localStorage.setItem("userId", newUser.payload.id); // Store the new user ID in localStorage
        navigate("/profile");
      });
    }
  });
};
