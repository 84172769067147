import React, { useState } from "react";
import Topbar from "./Topbar";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { urgentTastData } from "../utills/sidebardata";
import JobStatusChart from "./jobstatuschart";
import { messageData } from "../utills/sidebardata";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { generalData } from "../utills/sidebardata";
import Commingsoon from "./commingsoon";

import Calendar from "react-calendar";
import styled from "styled-components";

import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-calendar/dist/Calendar.css";
import { GetJobStatusChartData } from "../utills/chartdata";
import { default_events } from "../utills/defaultdata";

const StyledCalendar = styled(Calendar)`
  width: 400px;
  max-width: 100%;
  background-color: #f0f0f0;
  max-height: 400px;
  height: 100%;
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
  @media (max-width: 768px) {
    width: 100%;
  }
  .react-calendar__tile {
    color: #333;
  }

  .react-calendar__tile--active {
    background-color: #ccc;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-calendar__tile--active:hover {
    color: #fff; /* Change to the desired hover color */
    background-color: blue;
  }

  .react-calendar__tile--now {
    background-color: white;
    color: black;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__tile--now:hover {
    background-color: blue;
    color: #fff;
  }

  .react-calendar__navigation button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .react-calendar__navigation button:disabled {
    cursor: not-allowed;
  }

  .react-calendar__navigation button:focus {
    outline: none;
  }

  .react-calendar__navigation button.react-calendar__navigation__arrow {
    order: 1; /* Change order to move the buttons to the right */
  }
`;

const Button = styled.button`
  /* Button styles */
`;

// const data = {
//   labels: [
//     "Applied",
//     "Interviewing",
//     "Hired",
//     "Job Offered",
//     "Rejected",
//     "Inactive",
//   ],
//   datasets: [
//     {
//       label: "",
//       data: [20, 10, 20, 5, 10, 15], // Update with your actual data
//       backgroundColor: [
//         "rgba(90,200,250)", // Blue
//         "rgba(255, 206, 86, 0.6)", // Yellow
//         "rgba(75, 192, 192, 0.6)", // Green
//         "rgba(255, 165, 0)",
//         "rgba(255, 0, 0, 1)",
//         "rgba(88,86,214)",
//       ],
//     },
//   ],
// };

function Application({
  user,
  partner,
  setCommingSoon,
  commingSoon,
  handleReviewDashboardClick,
  userApplications
}) {
  const [searchStudent, setSearchStudent] = useState("");
  // const [value, onChange] = useState(new Date());
  // const [commingSoon, setCommingSoon] = useState(false);
  console.log(
    searchStudent
  );

  const [date, setDate] = useState(new Date());

  const navigationLabel = ({ date }) => {
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    return (
      <h2 className='react-calendar__navigation__label'>
        {month} {year}
      </h2>
    );
  };

  const handlePrevClick = () => {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      date.getDate()
    );
    setDate(newDate);
  };

  const handleNextClick = () => {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    setDate(newDate);
  };

  let recent_events ;
  if( partner?.events.length < 3)
  {
    recent_events= default_events()

  }
  else
  {
    recent_events = partner?.events?.slice(-3);
  }
  return (
    <div className='w-full flex flex-col gap-8 h-full min-h-screen bg-white px-2 lg:px-5 '>
      <Topbar />
      {/* ........................Section 1....................... */}
      <div className='flex flex-col lg:flex-row w-full justify-between lg:items-center'>
        <div className='w-auto'>
          <h1 className=' font-bold text-[32px] text-black font-default '>
            Welcome {user?.name}!
          </h1>
          <p className=' text-[16px] font-medium font-default'>
            Here's an overview of your students' progress:
          </p>
        </div>

        <div className=' lg:w-5/12 flex gap-8 md:justify-between items-center mt-3 lg:mt-0'>
          <div className='relative w-7/12 md:w-6/12 lg:w-9/12 '>
            <input
              type='text'
              name='Search text'
              className='outline-none border border-gary-200 py-3 px-3 w-full font-default text-gray-300'
              placeholder='Search for students'
              onChange={(e) => setSearchStudent(e.target.value)}
            />
            <div className='absolute right-3 top-3'>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </div>
          <div className='w-[60px] h-[60px] rounded-full bg-red-400'>
            <img
              className='w-[60px] h-[60px] rounded-full bg-red-400'
              src={user?.photoUrl}
              alt='img'
            />
          </div>
        </div>
      </div>

      {/* ...........................Section 2........................... */}
      <div className='w-full lg:flex-row gap-5   flex flex-col justify-between   2xl:gap-0'>
        <StyledCalendar
          value={date}
          onChange={setDate}
          showNavigation={true}
          navigationLabel={navigationLabel}
          prevLabel={
            <Button onClick={handlePrevClick}>
              <FontAwesomeIcon icon={faArrowCircleLeft} />
            </Button>
          }
          nextLabel={
            <Button onClick={handleNextClick}>
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </Button>
          }
        />

        <div className='bg-[#F0F0F0] w-full lg:w-7/12 2xl:flex-2 h-full  max-h-[326px] px-4 md:px-3  '>
          <h1 className='text-[20px] font-default font-semibold'>
            Urgent Task
          </h1>
          <div className='flex flex-col gap-3 justify-center w-full'>
            {urgentTastData.map((item, index) => {
              return (
                <div>
                  <div className='flex  justify-between items-center  border-b-2 border-indigo-600 pb-3 '>
                    <div className="w-full flex flex-row items-center justify-start cursor-grab"
                    onClick={()=> {handleReviewDashboardClick(item.navigateSideBarIndex)}} >

                     <div className='bg-white border mr-4 border-gray-800 rounded-xl w-[35px] h-[30px] flex flex-col items-center justify-center'>
                      <div className='bg-black  rounded-lg w-[20px] h-[20px] '></div>
                    </div>

                    <div>
                      <p className='text-sm md:text-[16px] font-default font-medium'>
                        {item.text}
                      </p>
                    </div>
                    </div>
                    <div>
                      {item.isToday ? (
                        <div className='flex items-center gap-2'>
                          <div className='w-[15px] h-[15px] rounded-full bg-black'></div>
                          <p className='text-sm md:text-[16px] font-default font-medium'>
                            Today
                          </p>
                        </div>
                      ) : (
                        <p className='text-[16px] font-default font-medium'>
                          Tomorrow
                        </p>
                      )}
                    </div>
                  </div>

                  <div className='h-[2px] w-full bg-gray-300' /> 
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* ...........................Section 3........................... */}
      <div className="flex flex-col items-center justify-center lg:flex-row lg:items-start lg:justify-between"  >
        <div className='w-full  lg:w-5/12 h-full px-1' >
          <h1 className='text-sm md:text-[20px] font-default font-semibold text-center w-full'>
            Students by Job Application Status
          </h1>
          <JobStatusChart data={GetJobStatusChartData(userApplications)} />
          <h1 className='text-sm md:text-[20px] font-default font-semibold text-center w-full mt-4'>
            Job Journey Status
          </h1>
        </div>

        <div className='w-full lg:w-7/12 flex lg:flex-row flex-col bg-white gap-10' >
          <div className=' w-full ' >
            <div className='bg-[#F0F0F0] px-3 py-3'>
              <h1 className='text-[20px] font-default font-semibold  w-full'>
                New Messages
              </h1>
              {messageData.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleReviewDashboardClick(item.navigateSideBarIndex)}
                    className='flex justify-around items-center gap-y-0 bg-white px-0 py-2 mt-3 lg:flex-col'
                  >
                    <div className='w-[40px] h-[40px] rounded-full bg-red-500'>
                      <img
                        src={item.image}
                        alt=''
                        className='w-[40px] h-[40px] rounded-full bg-red-500'
                      />
                    </div>
                    <div className='h-full'>
                      <p className='text-sm text-gray-500 font-medium cursor-grab'>
                        {item.title}
                      </p>
                      <h1 className='text-sm md:text-lg  font-semibold  w-full'>
                        {item.subtitle}
                      </h1>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className=' mt-4'>
              <h1 className='text-sm md:text-[20px] font-default font-semibold  w-full'>
                News and Updates
              </h1>
              <div className='flex justify-between flex-wrap items-center bg-[#F0F0F0]   px-2 py-2 mt-3 mb-3'>
               
              { recent_events?.map((item , index)=>{
              return(
                <div
                  key={index}
                  className='w-[85px] h-[50px] bg-white flex items-center overflow-hidden relative'
                  >
                <div
                className='absolute top-0 left-0 w-full h-full flex justify-center items-center px-4'
                >
            <p className='text-sm'>
              {item.title}
              </p>
                </div>
                </div>)
              })

               }
                
              </div>
            </div>
          </div>

          <div className='bg-[#F0F0F0] px-3 py-3 w-auto h-full' >
            <h1 className='text-[20px] font-default font-semibold  w-full'>
              What Would you like to do?
            </h1>
            <div className='grid grid-cols-2 gap-3 '>
              {generalData.map((item, index) => {
                // if (item.title === "Review Dashboard") {
                  return (
                    <div
                      key={index}
                      onClick={() => handleReviewDashboardClick(item.navigateSideBarIndex)} // Add onClick event handler here
                      className='flex flex-col justify-between flex-wrap items-center gap-y-3 bg-white px-2 py-2  '
                    >
                      <div className='w-[40px] h-[40px] rounded-full bg-red-500'>
                        <img
                          src={item.image}
                          alt=''
                          className='w-[40px] h-[40px] rounded-full bg-red-500'
                        />
                      </div>
                      <div className=' w-full h-[70px] md:h-[60px]'>
                        <p className='text-[10px] md:text-[14px flex flex-col items-center  font-semibold cursor-grab'>
                          {item.title}
                        </p>
                        <h1 className='text-xs text-gray-500  break-all text-center'>
                          {item.subtitle}
                        </h1>
                      </div>
                    </div>
                  );
                // }
                // return (
                //   <div
                //     onClick={() => setCommingSoon(true)}
                //     className='flex flex-col justify-between flex-wrap items-center gap-y-3 bg-white px-2 py-2  '
                //   >
                //     <div className='w-[40px] h-[40px] rounded-full bg-red-500'>
                //       <img
                //         src={item.image}
                //         alt=''
                //         className='w-[40px] h-[40px] rounded-full bg-red-500'
                //       />
                //     </div>
                //     <div className=' w-full h-[70px] md:h-[60px]'>
                //       <p className='text-[10px] md:text-[14px flex flex-col items-center  font-semibold cursor-grab '>
                //         {item.title}
                //       </p>
                //       <h1 className='text-sm text-gray-500  break-all text-center'>
                //         {item.subtitle}
                //       </h1>
                //     </div>
                //   </div>
                // );
              })}
            </div>
          </div>
        </div>
      </div>
      {commingSoon && <Commingsoon closeModal={() => setCommingSoon(false)} />}
    </div>
  );
}

export default Application;
